import moment, { Moment } from 'moment-timezone';

import { TIME_OPTION_PREFIX } from 'constants/DatePicker';
import {
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  SLOT_OPTION_PREFIX,
  TIME_FORMAT_24
} from 'constants/settings';
import { DateRange } from 'types/Appointment.types';
import { SlotDuration } from 'types/Branches.types';
import { TimeOption } from 'types/Calendar.types';

interface FormatDateType {
  (date?: string | Date, dateFormat?: string): string;
}
interface FormatDateTimeZoneType {
  (date: string | Date, timeZone: string, dateFormat?: string): string;
}

interface CheckDate {
  (date: Date): string;
}

interface AllDayCheckType {
  (startDate: Date, endDate: Date, startTime: string, endTime: string): boolean;
}

enum DATE_TYPES {
  TODAY = 'TODAY',
  FUTURE = 'FUTURE',
  PAST = 'PAST',
  NOW = 'NOW'
}

const checkDate: CheckDate = (date) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const selectedDate = new Date(date);
  selectedDate.setHours(0, 0, 0, 0);
  if (today < selectedDate) return DATE_TYPES.FUTURE;
  else if (today > selectedDate) return DATE_TYPES.PAST;
  else return DATE_TYPES.TODAY;
};

export const checkTime: CheckDate = (date) => {
  const today = new Date();
  today.setSeconds(0);
  const selectedDate = new Date(date);
  selectedDate.setSeconds(0);
  if (selectedDate > today) return DATE_TYPES.FUTURE;
  else if (selectedDate < today) return DATE_TYPES.PAST;
  else return DATE_TYPES.NOW;
};

export const formatTimeRange: (date?: Date) => string = (date) => {
  if (date) {
    if (new Date(date).getMinutes()) return moment(date).format('h:mm A');
    return moment(date).format('h A');
  }
  return '';
};

export const getWeekDates: (date: Date) => Moment[] = (date: Date) => {
  const startDate = moment(date).startOf('week');
  const dates = [];
  for (let i = 0; i <= 6; i++) {
    dates.push(moment(startDate).add(i, 'days'));
  }
  return dates;
};
const formatDate: FormatDateType = (date, dateFormat = DATE_FORMAT) =>
  date ? moment(date)?.format(dateFormat) : '';

const formatDateWithTimeZone: FormatDateTimeZoneType = (
  date,
  timeZone,
  dateFormat = DATE_FORMAT
) => (date ? moment(date)?.tz(timeZone)?.format(dateFormat) : '');

const allDayCheck: AllDayCheckType = (
  startDate,
  endDate,
  startTime,
  endTime
) => {
  const dayStart = new Date(startDate);
  dayStart.setHours(0);
  dayStart.setMinutes(0);
  const dayEnd = new Date(startDate);
  dayEnd.setHours(23);
  dayEnd.setMinutes(59);
  const formattedStartDate = `${moment(startDate).format(
    DATE_FORMAT
  )} ${startTime}`;
  const formattedEndDate = `${moment(endDate).format(DATE_FORMAT)} ${endTime}`;
  return (
    formattedStartDate === moment(dayStart).format(DATE_TIME_FORMAT) &&
    formattedEndDate === moment(dayEnd).format(DATE_TIME_FORMAT)
  );
};

const isBefore: (start?: string | Date, end?: string | Date) => boolean = (
  startTime = '',
  endTime = ''
) => {
  return moment(startTime, 'HH:mm').isBefore(moment(endTime, 'HH:mm'));
};

const formatSegmentTimeDiff: (timeDiff?: number) => string = (timeDiff = 0) => {
  const duration = moment.duration(Math.max(timeDiff, 0));
  const seconds = duration.seconds();
  const minutes = duration.minutes();
  const hours = duration.hours();
  const days = Math.trunc(duration.asDays());
  return `${days}:${hours}:${minutes}:${seconds}`;
};

const formatSegmentTimeHours: (timeDiff?: number) => string = (timeDiff = 0) =>
  moment.duration(Math.max(timeDiff, 0), 'milliseconds').asHours().toFixed(2);

const getTimeDiffMins = (startTime: Date, endTime: Date) => {
  const duration =
    Math.round((endTime.getTime() - startTime.getTime()) / 600) / 100;
  return duration;
};

const formatStartEndTime = (
  showTo: boolean,
  startDate: string | Date,
  endDate: string | Date,
  showDate = true
) => {
  if (startDate && endDate) {
    const date = moment(startDate).format(DATE_FORMAT);
    const startTime = moment(startDate).format('hh:mm A');
    const endTime = moment(endDate).format('hh:mm A');
    if (showDate) {
      return `${date} ${startTime} - ${endTime}`;
    }
    return `${startTime}${showTo ? ' to ' : ' - '}${endTime}`;
  }
  return '';
};
const timeRange = (startTiming: string, endTiming: string) => {
  if (startTiming && endTiming) {
    const formattedStartTiming = getFormattedTime(startTiming, 'h:mm A');

    const formattedEndTiming = getFormattedTime(endTiming, 'h:mm A');
    return `${formattedStartTiming} - ${formattedEndTiming}`;
  }
};
const getDateRangeLabel = (range: DateRange | undefined | null) => {
  const { startDate, endDate } = range || {};
  if (startDate && endDate) {
    return `${moment(startDate).format('DD/MM/YYYY')} - ${moment(
      endDate
    ).format('DD/MM/YYYY')}`;
  }
  return '';
};

/*time in format h AM - h PM or hh:mm AM - hh:mm PM  */
const getFormattedTime = (ISOTime: string, format?: string): string => {
  const ISODateTime = `${moment().format('YYYY-MM-DD')}T${ISOTime}.000Z`;
  const min = moment(ISODateTime).format('HH:mm')?.slice(2, 5);
  let timeFormat = '';
  if (format) {
    timeFormat = format;
  } else timeFormat = min === ':00' ? 'h A' : 'h:mm A';
  const finalTime = moment(ISODateTime).format(timeFormat);
  return finalTime;
};

const getFormattedTimeWithLocalTime = (
  ISOTime: string,
  format?: string
): string => {
  if (!ISOTime) return '';
  const timeMoment = moment(ISOTime, 'HH:mm:ss');
  const min = timeMoment.format('mm');
  const timeFormat = format || (min === '00' ? 'h A' : 'h:mm A');
  return timeMoment.format(timeFormat);
};

const sortAvailableHours = (availableHours: SlotDuration[]) => {
  return availableHours
    .map(({ endTime, startTime }) => ({
      endTime: endTime ? getFormattedTime(endTime) : '',
      startTime: startTime ? getFormattedTime(startTime) : ''
    }))
    ?.slice()
    ?.sort(({ startTime: startTime1 }, { startTime: startTime2 }) => {
      return moment
        .utc(moment(startTime1, ['h A', 'hh:mm A']))
        .diff(moment.utc(moment(startTime2, ['h A', 'hh:mm A'])));
    });
};

//time in format HH:MM:SS
const getActualTime = (time: string): string => {
  if (!time?.includes(':')) time?.concat(' :00');
  const ISODate = moment(time, 'h:mm A').toISOString();
  const timeFormat = ISODate.slice(
    ISODate.indexOf('T') + 1,
    ISODate.lastIndexOf('.')
  );
  return timeFormat;
};

const getActualTimeWithLocalTime = (time: string): string => {
  let formattedTime = time;
  if (!formattedTime.includes(':')) {
    formattedTime = formattedTime.concat(':00');
  }
  return moment(formattedTime, 'h:mm A').format('HH:mm:ss');
};

const isTimeBetween = (
  range: { start: string; end: string },
  value: string,
  inclusivity: '[]' | '()' | '[)' | '(]' = '[]'
): boolean => {
  const startTime = moment(range.start).format('HH:mm');
  const endTime = moment(range.end).format('HH:mm');
  const time = moment(value).format('HH:mm');
  return moment(time, 'HH:mm').isBetween(
    moment(startTime, 'HH:mm'),
    moment(endTime, 'HH:mm'),
    undefined,
    inclusivity
  );
};

function getTimeOptions(
  startTimestamp?: string,
  endTimestamp?: string
): TimeOption[] {
  // drop date
  const startTime = startTimestamp
    ? moment(startTimestamp).format('h:mm A')
    : '12:00 AM';
  const endTime = endTimestamp
    ? moment(endTimestamp).format('h:mm A')
    : '11:59 PM';
  // time with date
  const startDateTime = moment(startTime, 'h:mm A');
  const endDateTime = moment(endTime, 'h:mm A');

  const timeOptions: TimeOption[] = [];
  for (
    let currentTime = startDateTime;
    currentTime.isSameOrBefore(endDateTime);
    currentTime.add(15, 'minutes')
  ) {
    timeOptions.push({
      label: currentTime.format('h:mm A'),
      value: currentTime.format('h:mm A'),
      className: `${TIME_OPTION_PREFIX}${currentTime.format(
        SLOT_OPTION_PREFIX
      )}`
    });
  }
  return timeOptions;
}

const getDurationInMinutes = (startTime: Moment): string => {
  const endTime = moment();
  const timeDifference = moment.duration(endTime.diff(startTime));
  return timeDifference.asMinutes().toFixed(2);
};

const dayStartingIsoTime = (date: string) =>
  moment(date).startOf('day').toISOString();

const dayEndingIsoTime = (date: string | Date) =>
  moment(date).endOf('day').toISOString();

const getCurrentDateIso = () => {
  const now = moment();
  return now.startOf('day').toISOString();
};
const getDateNDaysAfter = (n: number) => moment().add(n, 'days');

const getBeginLimit = (startTime: string) => {
  return moment(startTime, TIME_FORMAT_24)
    .add(15, 'minutes')
    .format(TIME_FORMAT_24);
};

export {
  getDateNDaysAfter,
  isBefore,
  checkDate,
  DATE_TYPES,
  formatDate,
  allDayCheck,
  dayStartingIsoTime,
  dayEndingIsoTime,
  formatSegmentTimeHours,
  isTimeBetween,
  getDateRangeLabel,
  formatStartEndTime,
  getFormattedTime,
  getCurrentDateIso,
  formatSegmentTimeDiff,
  getActualTime,
  getTimeOptions,
  sortAvailableHours,
  getDurationInMinutes,
  formatDateWithTimeZone,
  getTimeDiffMins,
  timeRange,
  getActualTimeWithLocalTime,
  getFormattedTimeWithLocalTime,
  getBeginLimit
};
