import { pagePaths } from 'config/pages';
import { NotificationPreferenceEvent } from 'types/NotificationSettings.types';

const PATIENT_LIST_LIMIT = 50;

const DATE_FORMAT = 'DD/MM/YYYY';
const DATE_FORMAT_HYPHEN = 'YYYY-MM-DD';
const YEAR_FIRST_DATE_FORMAT = 'YYYY/MM/DD';
const DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mm';
const DATE_TIME_FORMAT_A = 'DD/MM/YYYY HH:mm A';
const DATE_TIME_FORMAT_PENDING = 'DD/MM/YYYY, HH:mm A';
const DATE_FORMAT_DD_MM_YY = 'DD/MM/YY';
const DATE_FORMAT_CALENDAR = 'DD MMM YYYY, dddd';
const DATE_FORMAT_CALENDAR_MOBILE = 'DD MMM YYYY';
const LONG_DATE_FORMAT = 'DD MMM YYYY, dddd';
const DATE_FORMAT_DOCTOR_NOTES = 'D/M/YYYY, dddd';
const DATE_FORMAT_RECENT_MESSAGE = 'ddd DD MMM hh:mm A';
const TIME_FORMAT_12 = 'h:mm A';
const TIME_FORMAT_24 = 'HH:mm';
const SLOT_OPTION_PREFIX = 'HH:mm:ss';
const CLINIC_CLOSED = 'Closed';
const TIME_FORMAT = 'hh:mm A';
const TIME_FIRST_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
const MESSAGE_DATE_FORMAT = 'Do MMMM YYYY. h:mm A';
const ALIGNER_DATE_FORMAT = 'Do MMMM YYYY.';
const SUBMISSION_DATE_FORMAT = 'DD MMMM YYYY, HH:mm';
const PRESCRIPTION_PHOTO_FORMAT = 'DD MMM yyyy, h:mm A';
const SETTINGS_BREADCRUMBS = [
  {
    label: 'dashboard_button.dashboard',
    pathname: pagePaths.dashboard
  },
  {
    label: 'settings_button.settings',
    pathname: pagePaths.settings
  }
];

const NOTIFICATION_PREFERENCES_BREADCRUMBS = [
  {
    label: 'settings_button.settings',
    pathname: pagePaths.settings
  },
  {
    label: 'notification.preferences',
    pathname: pagePaths.notificationPreferences
  }
];

const LINE_ENABLED_COUNTRIES = ['TW'];

const WHATSAPP_ENABLED_COUNTRIES = ['SG'];

const DAYS_OPTIONS = [
  {
    label: 'day.mon',
    value: 'MONDAY'
  },
  {
    label: 'day.tue',
    value: 'TUESDAY'
  },
  {
    label: 'day.wed',
    value: 'WEDNESDAY'
  },
  {
    label: 'day.thu',
    value: 'THURSDAY'
  },
  {
    label: 'day.fri',
    value: 'FRIDAY'
  },
  {
    label: 'day.sat',
    value: 'SATURDAY'
  },
  {
    label: 'day.sun',
    value: 'SUNDAY'
  }
];

const FREQUENCY_OPTIONS = [
  {
    label: 'immediate',
    value: 'Immediate'
  },
  {
    label: 'every',
    value: 'Weekly'
  }
];

const NOTIFICATION_PREFERENCE_EVENTS: Record<
  NotificationPreferenceEvent,
  NotificationPreferenceEvent
> = {
  prescriptionSubmission: 'prescriptionSubmission',
  fileResubmission: 'fileResubmission',
  treatmentPlanReview: 'treatmentPlanReview',
  inboxMessage: 'inboxMessage'
};

const NOTIFICATION_EVENT_CONFIGS: {
  name: NotificationPreferenceEvent;
  label: string;
}[] = [
  {
    name: NOTIFICATION_PREFERENCE_EVENTS.prescriptionSubmission,
    label: 'prescription.submission1'
  },
  {
    name: NOTIFICATION_PREFERENCE_EVENTS.fileResubmission,
    label: 'file.resubmission'
  },
  {
    name: NOTIFICATION_PREFERENCE_EVENTS.treatmentPlanReview,
    label: 'treatment.plan.review'
  },
  { name: NOTIFICATION_PREFERENCE_EVENTS.inboxMessage, label: 'inbox.message' }
];

export {
  SETTINGS_BREADCRUMBS,
  DATE_FORMAT,
  DATE_TIME_FORMAT_A,
  DATE_TIME_FORMAT_PENDING,
  DATE_FORMAT_CALENDAR,
  DATE_FORMAT_CALENDAR_MOBILE,
  DATE_FORMAT_DD_MM_YY,
  DATE_TIME_FORMAT,
  LONG_DATE_FORMAT,
  PATIENT_LIST_LIMIT,
  DATE_FORMAT_DOCTOR_NOTES,
  DATE_FORMAT_HYPHEN,
  DATE_FORMAT_RECENT_MESSAGE,
  TIME_FORMAT,
  TIME_FORMAT_12,
  TIME_FORMAT_24,
  CLINIC_CLOSED,
  ALIGNER_DATE_FORMAT,
  SLOT_OPTION_PREFIX,
  PRESCRIPTION_PHOTO_FORMAT,
  YEAR_FIRST_DATE_FORMAT,
  MESSAGE_DATE_FORMAT,
  TIME_FIRST_DATE_TIME_FORMAT,
  SUBMISSION_DATE_FORMAT,
  LINE_ENABLED_COUNTRIES,
  DAYS_OPTIONS,
  FREQUENCY_OPTIONS,
  WHATSAPP_ENABLED_COUNTRIES,
  NOTIFICATION_PREFERENCES_BREADCRUMBS,
  NOTIFICATION_EVENT_CONFIGS
};
