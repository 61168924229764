import { ChangeEvent } from 'react';

import { debounce } from 'lodash';

import { ErrorIcon } from 'assets/icons';
import { Tooltip } from 'components';
import { pagePaths } from 'config/pages';
import { TREATMENT_INFO_STATUS } from 'constants/options';
import {
  RETAINER_STAGE,
  TREATMENT_STAGES,
  TREATMENT_TYPE
} from 'constants/patientList';
import { PERMISSIONS } from 'constants/Permissions';
import {
  Family,
  SidepanelPreference,
  TreatmentFamilies
} from 'types/PatientList.types';
import { Option } from 'types/select';
import { PriorTreatment } from 'types/TreatmentPlanning.types';

import { checkIsExternal, isDataExist } from './common';
import { translate } from './locale';

export const getTabLabel = (
  type: string,
  refinementCount: number,
  isMultipleRefinement: boolean
) => {
  if (type === TREATMENT_TYPE.AFTERSALES || type === TREATMENT_TYPE.BRACES)
    return translate('primary_treatment.primary_treatment');
  if (type === TREATMENT_TYPE.RETAINER) return translate('retainer.retainer');
  if (type === TREATMENT_TYPE.REFINEMENT && !isMultipleRefinement)
    return translate('refinement.refinement');

  return translate('refinement.refinement') + ' ' + refinementCount;
};

const getTranslationforSubTab = (
  key: string,
  treatmentNo: number,
  totalCount: number
) => {
  const translation = translate(key);
  return totalCount > 1 ? `${translation} ${treatmentNo}` : translation;
};

export const getMainTabLabel = (type: string) => {
  switch (type) {
    case 'aligner':
      return translate('details.aligner');
    case 'retainer':
      return translate('retainer.retainer');
    case 'whitening':
      return translate('whitening.whitening');
    default:
      return translate(type);
  }
};

export const getSubTabLabel = (
  type: string,
  treatmentCount: {
    refinementCount: number;
    retainerCount: number;
    whiteningCount: number;
  },
  totalCount: {
    refinementCount: number;
    retainerCount: number;
    whiteningCount: number;
  }
) => {
  switch (type) {
    case TREATMENT_TYPE.AFTERSALES:
    case TREATMENT_TYPE.BRACES:
      return getTranslationforSubTab(
        'primary_treatment.primary_treatment',
        1,
        1
      );
    case TREATMENT_TYPE.REFINEMENT:
      return getTranslationforSubTab(
        'refinement.refinement',
        treatmentCount.refinementCount,
        totalCount.refinementCount
      );
    case TREATMENT_TYPE.ZENYUM_RETAINER:
    case TREATMENT_TYPE.RETAINER:
      return getTranslationforSubTab(
        'retainer.retainer',
        treatmentCount.retainerCount,
        totalCount.retainerCount
      );
    case TREATMENT_TYPE.WHITENING:
      return getTranslationforSubTab(
        'whitening.whitening',
        treatmentCount.whiteningCount,
        totalCount.whiteningCount
      );
    default:
      return translate(type);
  }
};

export const RemoveCheckedOptions = (
  data: Array<Option>,
  optionValue: string,
  e?: ChangeEvent<HTMLInputElement>
) => {
  let selectedValue;
  const isNoneOptionChecked =
    e?.target.value === optionValue && e?.target.checked;
  const isAnyOtherOptionChecked =
    e?.target.value !== optionValue && e?.target.checked;

  if (isNoneOptionChecked) {
    selectedValue = data.filter((option) => option?.value === optionValue);
  } else if (isAnyOtherOptionChecked) {
    selectedValue = data.filter((option) => option?.value !== optionValue);
  } else {
    selectedValue = data;
  }
  return selectedValue;
};

export const ALIGNER_TYPES = [
  TREATMENT_TYPE.AFTERSALES,
  TREATMENT_TYPE.REFINEMENT
];

const renderCaseOpsIssue = (
  hasCaseOpsIssue: boolean,
  loggedinuserGroups?: string[]
) => {
  return hasCaseOpsIssue && !checkIsExternal(loggedinuserGroups || []) ? (
    <Tooltip messageText={'Case ops issue'}>
      <ErrorIcon className='h-6 w-6 pl-1 pb-1 text-DARK_YELLOW' />
    </Tooltip>
  ) : (
    <></>
  );
};

const getSubTabs = (treatments: Family[], loggedinuserGroups?: string[]) => {
  const totalCount = treatments.reduce(
    (acc, { type }) => {
      if (type === TREATMENT_TYPE.REFINEMENT) {
        acc.refinementCount++;
      } else if (
        type === TREATMENT_TYPE.RETAINER ||
        type === TREATMENT_TYPE.ZENYUM_RETAINER
      ) {
        acc.retainerCount++;
      } else if (type === TREATMENT_TYPE.WHITENING) {
        acc.whiteningCount++;
      }
      return acc;
    },
    { refinementCount: 0, retainerCount: 0, whiteningCount: 0 }
  );

  const treatmentCount = {
    refinementCount: 0,
    retainerCount: 0,
    whiteningCount: 0
  };

  return treatments?.map(({ id, hasCaseOpsIssue, type }) => {
    const isRefinement = type === TREATMENT_TYPE.REFINEMENT;
    const isRetainer =
      type === TREATMENT_TYPE.RETAINER ||
      type === TREATMENT_TYPE.ZENYUM_RETAINER;
    const isWhitening = type === TREATMENT_TYPE.WHITENING;
    if (isRefinement) treatmentCount.refinementCount++;
    if (isRetainer) treatmentCount.retainerCount++;
    if (isWhitening) treatmentCount.whiteningCount++;
    return {
      id: id,
      label: getSubTabLabel(type, treatmentCount, totalCount),
      endIcon: renderCaseOpsIssue(hasCaseOpsIssue, loggedinuserGroups)
    };
  });
};

export const getPatientPageTabs = (
  family?: TreatmentFamilies,
  loggedinuserGroups?: string[]
) => {
  const tabs: {
    id: string;
    label: string;
    endIcon: JSX.Element;
    subTabs: { id: string; label: string; endIcon: JSX.Element }[];
  }[] = [];
  if (family) {
    Object.entries(family).forEach(([type, treatments]) => {
      if (isDataExist(treatments)) {
        const noOfTreatments = treatments.length;
        const hasSubTabs = noOfTreatments > 1;
        const hasCaseOpsIssue = treatments[noOfTreatments - 1]?.hasCaseOpsIssue;
        tabs.push({
          id: type,
          label: getMainTabLabel(type),
          endIcon: !hasSubTabs ? (
            renderCaseOpsIssue(hasCaseOpsIssue, loggedinuserGroups)
          ) : (
            <></>
          ),
          subTabs: getSubTabs(treatments, loggedinuserGroups)
        });
      }
    });
  }
  return tabs || [];
};

export const getTabs = (
  family?: Family[],
  loggedinuserGroups?: string[]
): { id: string; label: string; endIcon: JSX.Element }[] => {
  let refinementCount = 0;
  const totalRefinement =
    family?.filter(({ type }) => type === TREATMENT_TYPE.REFINEMENT)?.length ??
    0;
  const tabs = family?.map(({ id, hasCaseOpsIssue, type }) => {
    const isRefinement = type === TREATMENT_TYPE.REFINEMENT;
    if (isRefinement) refinementCount++;

    return {
      id: id,
      label: getTabLabel(type, refinementCount, totalRefinement > 1),
      endIcon:
        hasCaseOpsIssue && !checkIsExternal(loggedinuserGroups || []) ? (
          <Tooltip messageText={'Case ops issue'}>
            <ErrorIcon className='h-6 w-6 pl-1 pb-1 text-DARK_YELLOW' />
          </Tooltip>
        ) : (
          <></>
        )
    };
  });
  return tabs || [];
};

export const getShowSidePanel = (
  sidepanelPreference: Record<string, SidepanelPreference>,
  patientId?: string
) => {
  return patientId && sidepanelPreference[patientId]
    ? sidepanelPreference[patientId].isOpen
    : true;
};

export const getOutcomeNumber = (number: number) => {
  const suffixes = [
    'retainer.Nst_outcome',
    'retainer.Nnd_outcome',
    'retainer.Nrd_outcome'
  ];
  const lastDigit = number % 10;
  const suffix =
    lastDigit <= 3 ? suffixes[lastDigit - 1] : 'retainer.Nth_outcome';
  return translate(suffix, { N: number });
};

export const getLastTreatmentId = (tabs: any, index: number) => {
  const length = tabs[index]?.subTabs?.length || 0;
  const lastTreatment = tabs[index]?.subTabs[length - 1];
  return lastTreatment?.id;
};

export const getCreationProgressText = (treatmentType: string) => {
  let label = '';
  if (treatmentType === TREATMENT_TYPE.REFINEMENT) {
    label = 'refinement.creation';
  } else if (
    [TREATMENT_TYPE.ZENYUM_RETAINER, TREATMENT_TYPE.RETAINER].includes(
      treatmentType || ''
    )
  ) {
    label = 'retainer.creation';
  } else if (treatmentType === TREATMENT_TYPE.WHITENING) {
    label = 'whitening.creation';
  }
  return label;
};

export const isPaymentStatusVisible = (family?: Family) =>
  family?.stage === RETAINER_STAGE.PRESCRIPTION &&
  family?.status !== TREATMENT_INFO_STATUS.TREATMENT_LOST;

export const getPrescriptionFormStatuses = (
  priorTreatment?: PriorTreatment
) => {
  const { treatments, canPlanningProceed, completed } = priorTreatment || {};

  const isPriorTreatmentNone = treatments?.length === 0;
  const hasPlanningProceedValue = typeof canPlanningProceed === 'boolean';

  const isFormPartiallyDisabled = !(
    isPriorTreatmentNone || hasPlanningProceedValue
  );
  const isSubmitDisabled = !(
    isPriorTreatmentNone ||
    canPlanningProceed ||
    completed
  );

  return { isFormPartiallyDisabled, isSubmitDisabled };
};

export const tabHidePermissions: { [key: string]: string } = {
  retainer: PERMISSIONS.RETAINER_TAB_HIDE,
  whitening: PERMISSIONS.WHITENING_TAB_HIDE
};

export const getBreadcrumbs = (name: string, selectedTab: string) => {
  const baseBreadcrumb = [
    {
      label: name
    }
  ];

  const tabMap = {
    [TREATMENT_STAGES.ALL_ALIGNERS]: {
      label: translate('tab.all_aligners'),
      pathname: pagePaths?.patientList
    },
    [TREATMENT_STAGES.ALL_RETAINERS]: {
      label: translate('tab.all_retainers'),
      pathname: `${pagePaths.patientList}?product=retainer&type=${selectedTab}`
    },
    [TREATMENT_STAGES.ALL_WHITENING]: {
      label: translate('tab.all_whitenings'),
      pathname: `${pagePaths.patientList}?product=whitening&type=${selectedTab}`
    }
  };

  if (tabMap[selectedTab]) {
    return [tabMap[selectedTab], ...baseBreadcrumb];
  }
  return [
    {
      label: translate('tab.all_aligners'),
      pathname: pagePaths?.patientList
    },
    {
      label: selectedTab,
      pathname: `${pagePaths.patientList}?product=aligner&type=${selectedTab}`
    },
    ...baseBreadcrumb
  ];
};

export const debouncedFlip = debounce(
  (
    fileId: string,
    direction: string,
    handleImageFlip: (fileId: string, direction: string) => void
  ) => {
    handleImageFlip(fileId, direction);
  },
  500,
  { leading: true, trailing: false }
);
