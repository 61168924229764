import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import cx from 'classnames';
import { FormProvider, useForm } from 'react-hook-form';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { v4 as uuidv4 } from 'uuid';

import { QuestionIcon } from 'assets/icons';
import { ParticipantsList, Button, RhfCheckbox, Tooltip } from 'components';
import RhfSelect from 'components/Generics/Rhf/RhfSelect';
import { pagePaths } from 'config/pages';
import { EXTERNAL_DOCTOR, PATIENT } from 'constants/common';
import {
  CHAT_TOPIC_INITIATOR,
  INBOX_CONTEXT_TYPE,
  INBOX_ROLE,
  SEGMENT_STATUS
} from 'constants/inbox';
import { TITLE, CHAT_TRACK_EVENTS, VIEW_TYPE } from 'constants/segment';
import { TYPE_MAPPER } from 'constants/translationMaps';
import RhfUsersAutoSuggest from 'containers/generics/UserAutoSuggest/RhfUsersAutoSuggest';
import NewChatFileUpload from 'containers/NewChatFileUpload/NewChatFileUpload';
import {
  useCreateTicket,
  useGetActiveTreatment,
  useGetAllChatTopics,
  useGetNextTicketSequenceId,
  useGetTopicContextType,
  useGetTopicParticipantsList,
  useGetTreatmentById,
  useGetTreatmentPlans
} from 'services/hooks/Inbox.hooks';
import { useSegmentTrackChat } from 'services/hooks/Segment.hooks';
import {
  loginedUserDetails,
  selectedTicket,
  showChatMessage,
  ticketList
} from 'store/atoms';
import { inboxFilters } from 'store/atoms/inbox';
import { useIsExternalUser, useIsTPCouncil } from 'store/selectors/auth';
import { FilesData } from 'types/Files.types';
import {
  Participant,
  ChatTopics,
  CreateTicket,
  SendMessageInput,
  NewTicketInfo,
  GetAllTPVParam,
  MentionInput,
  Treatment,
  NewChatFormType
} from 'types/Inbox.types';
import { PatientDetail } from 'types/PatientList.types';
import { Option } from 'types/select';
import { isDataExist, translate, isMobileBrowser } from 'utils';
import {
  getFilterdTPVs,
  getFullName,
  getParticipantCount,
  getTopicName
} from 'utils/common';
import { useSetError } from 'utils/customHooks/useSetError';
import { formatSegmentTimeDiff } from 'utils/dateUtils';
import {
  getIfOwnerAndInitiatorIsSame,
  getMentionedUsersList,
  getSegmentUserDetails
} from 'utils/inbox';
import '@chat/chat-uikit/dist/index.css';
import { NewChatSchema } from 'validations/newChat';
import './NewChatForm.css';

import ParticipantDescription from './ParticipantDescription/ParticipantDescription';
import RhfChatInput from './RhfChatInput/RhfChatInput';

interface NewChatFormProps {
  onClose?: () => void;
  patientDetails?: PatientDetail;
  treatmentId?: string;
  selectedTreatmentId?: string;
  isFromPatientDetails?: boolean;
}

const NewChatForm: FC<NewChatFormProps> = ({
  onClose,
  patientDetails,
  treatmentId,
  selectedTreatmentId,
  isFromPatientDetails = false
}) => {
  const loggedUser = useRecoilValue(loginedUserDetails);
  const [files, setFiles] = useState<FilesData[]>([]);
  const [activeTreatment, setActiveTreatment] = useState<Treatment>();
  const zenchatId = useRef<number>();
  const [isUploading, setIsUploading] = useState(false);
  const setTicketsList = useSetRecoilState(ticketList);
  const [participantsList, setParticipantsList] = useState<Participant[]>([]);
  const userDetails = useRecoilValue(loginedUserDetails);
  const isExternalUser = useRecoilValue(useIsExternalUser);
  const isTPCouncil = useRecoilValue(useIsTPCouncil);
  const setSelectedTicket = useSetRecoilState(selectedTicket);
  const setShowChat = useSetRecoilState(showChatMessage);

  const segmentTrackData = useRef({
    opportunityId: 'none',
    opportunityName: 'none'
  });

  const [showMore, setShowMore] = useState(false);
  const { setError } = useSetError();
  const [selectedRole, setSelectedRole] = useState(INBOX_ROLE.INTERNAL);
  const [treatmentPlanList, setTreatementPlanList] = useState<Option[]>([]);
  const [internalChatTopicList, setInternalChatTopicList] = useState<Option[]>(
    []
  );
  const [singleLevelSelectOptions, setSingleLevelSelectOptions] = useState<
    Option[]
  >([]);
  const handleSegmentTrack = useSegmentTrackChat();

  /**
   * @description
   * initialize react hook form
   */

  const formMethods = useForm<NewChatFormType>({
    mode: 'onChange',
    resolver: yupResolver(NewChatSchema)
  });
  const {
    handleSubmit,
    watch,
    formState: { isValid },
    setValue,
    getValues
  } = formMethods;

  function getViewType() {
    if (isMobileBrowser()) {
      return 'mobile';
    }

    return isFromPatientDetails ? VIEW_TYPE.SIDE_PANEL : VIEW_TYPE.INBOX;
  }
  const resetTicketListFilters = useResetRecoilState(inboxFilters);

  const handleNewChatconfig = {
    onCompleted: (data: NewTicketInfo) => {
      const { createTicket } = data;
      setSelectedTicket(data.createTicket);
      setTicketsList((oldTickets) => [data.createTicket, ...oldTickets]);

      const tpvIndex = createTicket.ticketTopicContextInfo.findIndex(
        (context) => context.contextType === INBOX_CONTEXT_TYPE.TPV
      );
      const tpIndex = createTicket.ticketTopicContextInfo.findIndex(
        (context) => context.contextType === INBOX_CONTEXT_TYPE.TP
      );
      const patientIndex = createTicket.ticketTopicContextInfo.findIndex(
        (context) => context.contextType === INBOX_CONTEXT_TYPE.PATIENT
      );
      const getContextType = () => {
        if (tpvIndex !== -1) {
          return INBOX_CONTEXT_TYPE.TPV;
        } else if (tpIndex !== -1) {
          return INBOX_CONTEXT_TYPE.TP;
        }
        return 'none';
      };

      const segmentData = {
        owner_initiator_same: getIfOwnerAndInitiatorIsSame(participantsList)
          ? 'yes'
          : 'no',
        zenchat_ID: createTicket.id,
        zenchat_topic: createTicket.topicInfo.topicName,
        zenchat_other_topic: showMore ? 'yes' : 'no',
        zenchat_context_type: getContextType(),
        treatment_plan_version_id: 'none',
        files_no: files.length,
        patient_id: '',
        patient_name: '',
        mark_as_urgent_checkbox: createTicket.isUrgent ? 'yes' : 'no',
        no_of_participants: createTicket.ticketUsers.length,
        opportunity_id: segmentTrackData.current.opportunityId,
        opportunity_name: segmentTrackData.current.opportunityName,
        treatment_plan_id: 'none',
        treatment_plan_version: 'none',
        treatment_plan_type: 'none'
      };
      if (tpIndex !== -1) {
        segmentData.treatment_plan_id =
          createTicket.ticketTopicContextInfo[tpIndex].contextValueId;
        segmentData.treatment_plan_type =
          createTicket.ticketTopicContextInfo[tpIndex].contextType;
      }
      if (tpvIndex !== -1) {
        segmentData.treatment_plan_version_id =
          createTicket.ticketTopicContextInfo[tpvIndex].contextValueId;
        segmentData.treatment_plan_version =
          createTicket.ticketTopicContextInfo[tpvIndex].contextValue;
        segmentData.treatment_plan_type =
          createTicket.ticketTopicContextInfo[tpvIndex].contextType;
      }
      const zenchatUpdateBqData = {
        zenchat_ID: createTicket.id,
        chat_created_ts: createTicket.createdAt,
        no_of_participants: getParticipantCount(participantsList)
      };
      const zenchatUpdateUserBqData = {
        opportunity_name: segmentTrackData.current.opportunityName,
        patient_country: activeTreatment?.patient?.country,
        zenchat_status: SEGMENT_STATUS.OPENED,
        patient_name: '',
        zenchat_urgent: createTicket.isUrgent ? 'Yes' : 'No',
        zenchat_topic: createTicket.topicInfo.topicName,
        ...zenchatUpdateBqData,
        ...getSegmentUserDetails(participantsList)
      };
      if (patientIndex !== -1) {
        segmentData.patient_id =
          createTicket.ticketTopicContextInfo[patientIndex].contextValueId;
        segmentData.patient_name =
          createTicket.ticketTopicContextInfo[patientIndex].contextValue || '';
        zenchatUpdateUserBqData.patient_name =
          createTicket.ticketTopicContextInfo[patientIndex].contextValue || '';
      }
      handleSegmentTrack(
        CHAT_TRACK_EVENTS.ZENCHAT_UPDATE_BQ,
        zenchatUpdateBqData
      );
      handleSegmentTrack(CHAT_TRACK_EVENTS.NEW_CHAT_CREATED, {
        ...segmentData
      });
      handleSegmentTrack(
        CHAT_TRACK_EVENTS.ZENCHAT_USER_UPDATE_BQ,
        zenchatUpdateUserBqData
      );
      if (onClose) onClose();
      if (!isFromPatientDetails) {
        resetTicketListFilters();
      } else {
        setShowChat(true);
      }
    }
  };

  /**
   * @description
   * APIs initializations
   */
  const [getAllChatTopics, { data: topicList }] = useGetAllChatTopics({
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true
  });
  const [handleCreateNewChat, { loading }] =
    useCreateTicket(handleNewChatconfig);
  const [getSequenceId, { data: sequenceId }] = useGetNextTicketSequenceId({
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache'
  });
  zenchatId.current = sequenceId?.getNextTicketSequenceId;
  const [getTopicParticipantsList, { data: TopicParticipantsList }] =
    useGetTopicParticipantsList({
      fetchPolicy: 'no-cache',
      nextFetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true
    });
  const [getTreatmentPlan, { data: treatmentPlanData }] = useGetTreatmentPlans({
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true
  });
  const [getTopicContext, { data: topicContextData }] = useGetTopicContextType({
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true
  });
  const [getTreatmentById] = useGetTreatmentById({
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (data.getTreatmentById.id) {
        setActiveTreatment(data.getTreatmentById);
      }
    }
  });
  const [getActiveTreatement] = useGetActiveTreatment({
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (isDataExist(data.getActiveTreatmentsForPatient)) {
        setActiveTreatment(data.getActiveTreatmentsForPatient[0]);
        handleGetTreatementPlan(
          watchPatient.id,
          data.getActiveTreatmentsForPatient[0].id
        );
      }
    }
  });

  /**
   * @description
   * Watch all form fields
   */
  const watchPatient = watch('patient');
  const watchTopic = watch('topic');
  const watchShowTp = watch('showTp');

  function getPageData() {
    const { topic, markasurgent } = getValues();
    return {
      zenchat_topic: topic?.label || 'none',
      zenchat_urgent: markasurgent ? 'Yes' : 'No'
    };
  }

  const formatExternalTopicList = (allChatTopics: ChatTopics[]) => {
    const options: Option[] = allChatTopics.map((option) => ({
      label: getTopicName(option.code),
      value: option.id
    }));
    setSingleLevelSelectOptions(options);
  };
  const formatTopicList = (allChatTopics: ChatTopics[]) => {
    const options = allChatTopics.map((option) => {
      return {
        label: getTopicName(option.code),
        value: option.id
      };
    });

    setInternalChatTopicList(options);
  };

  /**
   * @description
   * To get the topic from the server
   * @param treatmentStages string treatment stage
   * @param moreOptions boolean
   */
  const handleGetAllChatTopics = (treatmentStages: Array<string>) => {
    const param = {
      variables: {
        userRole: [
          isExternalUser
            ? CHAT_TOPIC_INITIATOR.EXTERNAL_DOCTOR
            : CHAT_TOPIC_INITIATOR.INTERNAL_ZENYUM_STAKEHOLDER
        ],
        patientTreatmentStage: treatmentStages,
        listAllTopics: isExternalUser ? undefined : true
      },
      notifyOnNetworkStatusChange: true
    };
    getAllChatTopics(param);
  };

  /**
   * @description
   * Handele show more clicked
   * @param isShowMoreSelected boolean show more clicked or not
   */

  /**
   * @description
   * function to handle format treatment plan
   */
  const formatTreatmentPlan = () => {
    const tpvOptions: Option[] = [];
    const treatementPlan = treatmentPlanData?.getTreatmentPlans?.edges;
    const contextType = topicContextData?.getTopicContextType;

    const isTpvExist = contextType?.filter(
      (context) => context.contextType === INBOX_CONTEXT_TYPE.TPV
    );
    if (isTpvExist?.length) {
      treatementPlan?.forEach((treatmentplanOption) => {
        if (isDataExist(treatmentplanOption.versions)) {
          getFilterdTPVs(treatmentplanOption.versions || []).forEach(
            (treatmentVersion) => {
              const option: Option = {
                label: '',
                value: ''
              };
              const tpvType = treatmentVersion?.type
                ? translate(TYPE_MAPPER[treatmentVersion?.type])
                : null;
              option.label = [tpvType, treatmentVersion?.name]
                .filter(Boolean)
                .join(' \u2758 ');
              option.value = {
                TP: {
                  contextType: INBOX_CONTEXT_TYPE.TP,
                  contextValue: treatmentplanOption.name || 'Treatment Plan',
                  contextValueId: treatmentplanOption.id
                },
                TPV: {
                  contextType: INBOX_CONTEXT_TYPE.TPV,
                  contextValue: treatmentVersion.name,
                  contextValueId: treatmentVersion.id
                }
              };
              tpvOptions.push(option);
            }
          );
        }
        //commented as part of ZN-680
        else {
          const label = treatmentplanOption.name || 'Treatment Plan';
          const option: Option = {
            label,
            value: {
              TP: {
                contextType: INBOX_CONTEXT_TYPE.TP,
                contextValue: label,
                contextValueId: treatmentplanOption.id
              }
            }
          };
          tpvOptions.push(option);
          setValue('tpv', option);
        }
      });
    } else {
      treatementPlan?.forEach((treatmentplanOption) => {
        if (isDataExist(treatmentplanOption.versions)) {
          const option: Option = {
            label: treatmentplanOption.name,
            value: {
              TP: {
                contextType: INBOX_CONTEXT_TYPE.TP,
                contextValue: treatmentplanOption.name,
                contextValueId: treatmentplanOption.id
              }
            }
          };
          tpvOptions.push(option);
        }
      });
    }
    setTreatementPlanList(tpvOptions);
  };

  const treatementStage: string[] = useMemo(() => {
    if (activeTreatment?.id) {
      const { details, type } = activeTreatment;
      segmentTrackData.current.opportunityId = details.opportunityId;
      segmentTrackData.current.opportunityName = type;

      const treatmentStage = activeTreatment.stage;

      if (!treatmentStage) {
        setError(translate('ERROR.NO_ACTIVE_TREATMENT'));
      }
      return [treatmentStage] || [];
    }
    return [];
  }, [activeTreatment]);

  /**
   * @description
   * Hook to get the sequence no on the inbox page load
   */
  useEffect(() => {
    getSequenceId();
  }, []);

  useEffect(() => {
    const startTime = new Date();

    return () => {
      const endTime = new Date();
      const duration = endTime.getTime() - startTime.getTime();
      window.analytics?.page(TITLE.NEW_CHAT, {
        path: pagePaths.newChat,
        view_type: getViewType(),
        duration: formatSegmentTimeDiff(duration),
        zenchat_status: SEGMENT_STATUS.OPENED,
        zenchat_ID: zenchatId.current || 'none',
        ...getPageData()
      });
    };
  }, []);

  useEffect(() => {
    const checkIsTpExist = topicContextData?.getTopicContextType.find(
      (context) => context.contextType === INBOX_CONTEXT_TYPE.TP
    );
    setTimeout(() =>
      setValue('showTp', !!checkIsTpExist && isDataExist(treatmentPlanList))
    );
  }, [topicContextData, treatmentPlanList, setValue]);

  /**
   * @description
   * hook to get the treatement topics when active treatement data got changes
   */
  useEffect(() => {
    if (watchPatient?.id) {
      handleGetAllChatTopics(treatementStage);
    }
  }, [activeTreatment]);

  /**
   * @description
   * Hook to call format topic list when topiclist got changed
   */
  useEffect(() => {
    if (isDataExist(topicList?.getAllChatTopics)) {
      if (isExternalUser) {
        formatExternalTopicList(topicList?.getAllChatTopics || []);
      } else {
        formatTopicList(topicList?.getAllChatTopics || []);
      }
    }
  }, [topicList]);

  useEffect(() => {
    if (
      isDataExist(treatmentPlanData?.getTreatmentPlans?.edges) &&
      isDataExist(topicContextData?.getTopicContextType)
    ) {
      formatTreatmentPlan();
    } else {
      setTreatementPlanList([]);
    }
  }, [topicContextData?.getTopicContextType]);

  useEffect(() => {
    const participantList = TopicParticipantsList?.getTopicParticipants || [];
    setParticipantsList([...participantList]);
  }, [TopicParticipantsList]);
  /**
   * @description
   * function to get the TP based on the patientId
   */
  const handleGetTreatementPlan = useCallback(
    (id: string, activeTreatmentId?: string) => {
      const rejectedBy = isExternalUser
        ? [EXTERNAL_DOCTOR, PATIENT]
        : undefined;

      const variables: GetAllTPVParam = {
        params: { patientId: id },
        paginationInfo: {
          offset: 0,
          limit: 10
        },
        rejectedBy
      };
      if (isFromPatientDetails && selectedTreatmentId) {
        variables.params.treatmentId = selectedTreatmentId;
      } else {
        variables.params.treatmentId = activeTreatmentId;
      }
      getTreatmentPlan({ variables });
    },
    [getTreatmentPlan]
  );

  /**
   * @description
   * Hook to reset all form field when patient name got changed
   */
  useEffect(() => {
    setShowMore(false);
    setValue('topic', []);
    setValue('tpv', []);
    setTreatementPlanList([]);
    setInternalChatTopicList([]);
    setSingleLevelSelectOptions([]);
    if (watchPatient?.id && !isFromPatientDetails) {
      getActiveTreatement({
        variables: {
          patientId: watchPatient.id
        }
      });
    } else if (isFromPatientDetails && watchPatient?.id && treatmentId) {
      handleGetTreatementPlan(watchPatient.id);
      getTreatmentById({
        variables: {
          id: treatmentId
        }
      });
    }
  }, [watchPatient]);

  useEffect(() => {
    if (patientDetails?.id) {
      const patientDetail = { ...patientDetails };
      patientDetail.name = `${patientDetails.firstName} ${patientDetails.lastName}`;
      setValue('patient', patientDetail);
    }
  }, [patientDetails]);

  /**
   * @description
   * Hook to get the topic context based on the topic change
   */
  useEffect(() => {
    setValue('tpv', []);
    setTreatementPlanList([]);
    if (watchTopic?.value) {
      const param = { variables: { topicId: watchTopic.value } };
      getTopicContext(param);
      const topicParticipantsParam = {
        variables: {
          topicId: watchTopic?.value,
          patientId: watchPatient.id,
          clinicCountryCode: activeTreatment?.clinic?.country
        }
      };
      getTopicParticipantsList(topicParticipantsParam);
    }
  }, [watchTopic]);

  const getSendMessageInput = (message: MentionInput) => {
    let messageText: SendMessageInput[] = [];
    if (message.text) {
      messageText = [
        {
          id: uuidv4(),
          sendBy: userDetails.id, // TODO:this will be taken from the token
          customAttributes: {
            isInternal: selectedRole === INBOX_ROLE.INTERNAL
          },
          ...message
        }
      ];
    }

    if (isDataExist(files)) {
      const messageInput: SendMessageInput[] = files.map((file) => {
        return {
          sendBy: userDetails.id, // TODO:this will be taken from the token
          text: '',
          id: uuidv4(),
          documentInfo: {
            name: file.fileName || '',
            ext: file.file?.name.split('.').pop() || '',
            type: file.file?.type || '',
            filePath: file.filePath || '',
            fileSize: file.file?.size || 0
          },
          customAttributes: { isInternal: selectedRole === INBOX_ROLE.INTERNAL }
        };
      });
      return [...messageText, ...messageInput];
    }
    return [...messageText];
  };

  const getTicketUser = () => {
    return participantsList.map((participant) => {
      return {
        groupId: participant.groupId,
        userId: participant.userId,
        teamId: participant.userTeamId,
        isExternal: participant.isExternal
      };
    });
  };

  const getHeadClinicId = () => {
    const headClinicId = activeTreatment?.clinic?.headClinicId;
    const clinicId = activeTreatment?.clinic?.id;
    return headClinicId || clinicId;
  };

  const onSubmitHandler = (formValues: NewChatFormType) => {
    const { topic, markasurgent, patient, tpv, message } = formValues;

    const param: CreateTicket = {
      id: sequenceId?.getNextTicketSequenceId,
      topicId: topic.value,
      ticketTopicContextInfo: [],
      clinicId: activeTreatment?.clinic?.id,
      treatmentId: activeTreatment?.id,
      ticketUsers: getTicketUser(),
      isUrgent: markasurgent,
      sendMessageInput: getSendMessageInput(message),
      customData: {
        headClinicId: getHeadClinicId()
      }
    };
    if (patient.id) {
      const patientContextInfo = {
        contextType: INBOX_CONTEXT_TYPE.PATIENT,
        contextValue: getFullName({
          firstName: patient.firstName,
          lastName: patient.lastName,
          salutation: ''
        }),
        contextValueId: patient.id
      };
      param.ticketTopicContextInfo.push(patientContextInfo);
    }
    if (tpv?.value?.TP) {
      param.ticketTopicContextInfo.push(tpv.value.TP);
    }
    if (tpv?.value?.TPV) {
      param.ticketTopicContextInfo.push(tpv.value.TPV);
    }
    handleCreateNewChat({ variables: { param, loginedUserId: loggedUser.id } });
  };

  const onFileUploadChange = useCallback((filesList: FilesData[]) => {
    setFiles(filesList);
  }, []);

  /**
   * @description function to get mention suggestion formatted and filtered
   * @return {UserMentionInfo[]} mentioned user suggestions array
   */
  const mentionUsersList = useMemo(() => {
    //@description if it is external user don't show any notifications
    if (isExternalUser) {
      return [];
    }
    return getMentionedUsersList(participantsList, userDetails.id, isTPCouncil);
  }, [participantsList]);

  /**
   * @description function to filter mentioned user based on the search text
   * @param searchText searched text
   * @return {UserMentionInfo} filtered mention suggestions
   */
  const getChatUsers = useCallback(
    (searchText?: string) => {
      if (searchText) {
        return mentionUsersList.filter((mentionDetails) =>
          mentionDetails.name
            .toLocaleLowerCase()
            .includes(searchText.toLocaleLowerCase())
        );
      }
      return mentionUsersList;
    },
    [mentionUsersList]
  );

  const [isAddParticipantPopupOpen, setIsAddParticipantPopupOpen] =
    useState(false);

  return (
    <div className='mt-6  new-chat-form'>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmitHandler)}>
          {!isFromPatientDetails ? (
            <RhfUsersAutoSuggest
              inputStyle='h-6 bg-transparent focus:outline-none flex-grow mx-3 placeholder:debouncedText-sm placeholder:leading-6 md:debouncedText-lg py-3 text-14px '
              containerStyle='flex  items-center justify-between border-solid  border md:border-2 rounded md:rounded-lg px-4 py-3 md:px-3 md:py-2 '
              name='patient'
              placeholder={translate('search.placeholder')}
              searchListWrapperClassName='!mt-1'
            />
          ) : (
            <h5 className='text-sm font-semibold'>
              {getFullName({
                firstName: patientDetails?.firstName,
                lastName: patientDetails?.lastName,
                salutation: ''
              })}
            </h5>
          )}
          <div className='mt-4'>
            {isExternalUser ? (
              <RhfSelect
                name='topic'
                options={singleLevelSelectOptions}
                inputProps={{
                  controlShouldRenderValue: true,
                  isMulti: false,
                  placeholder: translate('fields.whatToDiscuss')
                }}
              />
            ) : (
              <RhfSelect
                name='topic'
                options={internalChatTopicList}
                inputProps={{
                  controlShouldRenderValue: true,
                  isMulti: false,
                  placeholder: translate('fields.whatToDiscuss')
                }}
              />
            )}
          </div>
          {!isExternalUser && <ParticipantDescription />}
          {watchTopic?.value && (
            <>
              {!isExternalUser && !isFromPatientDetails && (
                <div className='max-w-lg '>
                  <ParticipantsList
                    participantsList={participantsList}
                    setParticipantsList={setParticipantsList}
                    isAddParticipantPopupOpen={isAddParticipantPopupOpen}
                    setIsAddParticipantPopupOpen={setIsAddParticipantPopupOpen}
                    isTPCouncil={isTPCouncil}
                  />
                </div>
              )}
              {watchShowTp && (
                <div className='mt-4'>
                  <RhfSelect
                    options={treatmentPlanList}
                    name='tpv'
                    inputProps={{
                      placeholder: treatmentPlanList?.[0]?.value?.TPV
                        ? translate('fields.TPV.placeholder')
                        : translate('INBOX.SELECT_TP'),
                      controlShouldRenderValue: true
                    }}
                  ></RhfSelect>
                </div>
              )}
            </>
          )}
          <RhfChatInput
            name='message'
            selectedRole={selectedRole}
            setSelectedRole={setSelectedRole}
            getChatUsers={getChatUsers}
          />
          <div className='mt-2'>
            <NewChatFileUpload
              title={translate('REFERRALS.PATIENT_FILES.PHOTOS')}
              fileDropAreaTitle='image'
              className='mb-6 w-full'
              onChange={onFileUploadChange}
              isUploading={setIsUploading}
              sequenceId={sequenceId?.getNextTicketSequenceId}
            />
          </div>
          <div className='mt-6 flex items-center'>
            <RhfCheckbox
              name='markasurgent'
              className='text-sm font-normal'
              label={translate('actions.markUrgent')}
              defaultValue={false}
            />
            <Tooltip
              positions={['right']}
              tooltipStyle='px-3 py-2 text-10px rounded-8px bg-LIGHT_GRAY text-DISABLED w-40 leading-4'
              messageText={translate('descriptive.tooltipForUrgent')}
            >
              <QuestionIcon className='ml-2 w-4 h-4' />
            </Tooltip>
          </div>
          {!isExternalUser && isFromPatientDetails && watchTopic?.value && (
            <div className='max-w-lg mt-6'>
              <ParticipantsList
                participantsList={participantsList}
                setParticipantsList={setParticipantsList}
                isAddParticipantPopupOpen={isAddParticipantPopupOpen}
                setIsAddParticipantPopupOpen={setIsAddParticipantPopupOpen}
                isTPCouncil={isTPCouncil}
              />
            </div>
          )}
          <div className='grid justify-items-end mt-10 md:mt-5 md:mb-4'>
            <Button
              type='submit'
              disabled={!isValid || loading || isUploading}
              className={cx(
                'bg-PRIMARY text-WHITE px-30px py-4 w-full text-sm md:text-base md:px-8 md:py-3 xxs:w-full',
                { ' md:w-40 ': !isFromPatientDetails }
              )}
            >
              {translate('actions.Send1')}
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default NewChatForm;
