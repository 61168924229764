import { FC, useEffect, useState } from 'react';

import cx from 'classnames';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { createPortal } from 'react-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import {
  CloseIcon,
  MailOutlineIcon,
  FlagIcon,
  NewTabIcon,
  ReassignIcon,
  CopyLink,
  RoundTickIcon,
  RightPanelIcon,
  ParticipantIcon,
  PhotoLibraryIcon
} from 'assets/icons';
import { AddTeamMember, CloseTicketPopup, ReassignOwner } from 'components';
import { CopyText } from 'components/CopyText/CopyText';
import { If } from 'components/Generics';
import SnoozeButton from 'components/SnoozeTicketPopup/SnoozeButton';
import SnoozeTicketPopup from 'components/SnoozeTicketPopup/SnoozeTicketPopup';
import { MOBILE_SCREEN_WIDTH } from 'constants/common';
import { CHAT_HEADER_ICONS } from 'constants/inbox';
import { useGetActiveTreatment } from 'services/hooks/Inbox.hooks';
import {
  useSnoozeTicket,
  useUnsnoozeTicket
} from 'services/hooks/Ticket.hooks';
import { GET_TICKET_COUNT } from 'services/query/inbox';
import { selectedTicketActiveTreatement, ticketList } from 'store/atoms';
import { Participant } from 'types/Inbox.types';
import {
  SnoozeTicketOutput,
  TicketTopicContextInfo,
  UnsnoozeTicketOutput
} from 'types/Tickets.types';
import { customTwMerge, isDataExist, translate, useViewport } from 'utils';
import { useToggle } from 'utils/customHooks/useToggle';
import { showToast } from 'utils/toast/toast.util';

import ChatHeaderTooltip from './ChatHeaderTooltip';
import ChatGallery from '../ChatGallery/ChatGallery';
import { useChatGallery } from '../ChatGallery/ChatGallery.hook';

interface ChatHeaderProps {
  title: string;
  patientName: string;
  chatId: number;
  subtitle: string;
  isUrgent: boolean;
  hasPermission: boolean;
  isUnread: boolean;
  isClosed: boolean;
  showCloseButton: boolean;
  isFromPatientDetails?: boolean;
  isUserPartOfTicket?: boolean;
  isExternalUser: boolean;
  tpvDetails: TicketTopicContextInfo | undefined;
  tpDetails: TicketTopicContextInfo | undefined;
  patientUrl: string;
  handleUrgent?: () => void;
  handleUnread?: (isUnread: boolean) => void;
  onClose?: () => void;
  userList?: Participant[];
  onPatientCardOpen?: () => void;
  copyLink?: () => void;
  showTPVLink: boolean;
  patientId: string;
  showSnooze?: boolean;
  snoozeUntill?: Date;
  snoozeType?: string;
  getSelectedTicketDetails?: () => void;
  setShowSidePanel: (value: boolean) => void;
  showSidePanel?: boolean;
  isTPCouncil: boolean;
}

const ChatHeader: FC<ChatHeaderProps> = ({
  title,
  chatId,
  subtitle,
  isUrgent,
  isClosed,
  isUnread,
  patientId,
  tpDetails,
  patientUrl,
  tpvDetails,
  showTPVLink,
  patientName,
  hasPermission,
  isExternalUser,
  userList = [],
  snoozeType,
  showCloseButton = false,
  isUserPartOfTicket = true,
  isFromPatientDetails = false,
  showSnooze = false,
  onClose,
  copyLink,
  handleUnread,
  handleUrgent,
  onPatientCardOpen,
  snoozeUntill,
  getSelectedTicketDetails,
  setShowSidePanel,
  showSidePanel,
  isTPCouncil
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isReassignOpen, setIsReassignOpen] = useState(false);
  const [isSnoozePopupOpen, setIsSnoozePopupOpen] = useState(false);
  const [showUserList, setShowUserList] = useState(false);
  const activeTreatmentData = useRecoilValue(selectedTicketActiveTreatement);
  const setTicketsList = useSetRecoilState(ticketList);
  const { width } = useViewport();
  const isMobile = width < MOBILE_SCREEN_WIDTH;
  const isSnoozed = !!snoozeUntill;
  const [getActiveTreatement, { data: activeTreatmentMobile }] =
    useGetActiveTreatment({
      fetchPolicy: 'no-cache',
      nextFetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true
    });

  useEffect(() => {
    if (isMobile) {
      getActiveTreatement({
        variables: {
          patientId
        }
      });
    }
  }, []);

  const getsSalesforceLink = () => {
    const salesforceLink = {
      tp: '',
      tpv: ''
    };
    const activeData = isMobile
      ? activeTreatmentMobile?.getActiveTreatmentsForPatient || []
      : activeTreatmentData;

    if (isDataExist(activeData)) {
      activeData.forEach((activeTreatment) => {
        activeTreatment.treatmentPlan?.forEach((treatmentPlan) => {
          if (treatmentPlan.id === tpDetails?.contextValueId) {
            salesforceLink.tp = treatmentPlan?.salesforceInfo?.salesforceLink;
            treatmentPlan.versions?.forEach((version) => {
              if (version.id === tpvDetails?.contextValueId) {
                salesforceLink.tpv = version.salesforceInfo?.salesforceLink;
              }
            });
          }
        });
      });
    }
    return salesforceLink;
  };

  const getSubtitle = () => {
    const salesforceLink = getsSalesforceLink();
    return (
      <div>
        {`${title} | `}
        {!isExternalUser &&
        showTPVLink &&
        (salesforceLink.tpv || salesforceLink.tp) ? (
          <a
            href={salesforceLink.tpv || salesforceLink.tp}
            target='_blank'
            rel='noreferrer'
            className='text-PRIMARY underline'
          >
            {subtitle}
          </a>
        ) : (
          <span>{subtitle} </span>
        )}
      </div>
    );
  };

  const showTpvLink = () => {
    const salesforceLink = getsSalesforceLink();
    return (
      !isExternalUser &&
      showTPVLink &&
      (!!salesforceLink.tpv || !!salesforceLink.tp)
    );
  };

  const getSubtitleForPatientDetails = () => {
    const salesforceLink = getsSalesforceLink();

    return (
      <a
        href={salesforceLink.tpv || salesforceLink.tp}
        target='_blank'
        rel='noreferrer'
        className='text-PRIMARY font-bold underline'
      >
        {subtitle}
      </a>
    );
  };

  const checkPermission = (permission: string): boolean => {
    switch (permission) {
      case CHAT_HEADER_ICONS.MARK_AS_NOT_READ:
        return !isClosed && isUserPartOfTicket;
      case CHAT_HEADER_ICONS.MARK_AS_NOT_URGENT:
        return (
          hasPermission && !isExternalUser && !isClosed && isUserPartOfTicket
        );
      case CHAT_HEADER_ICONS.OPEN_PATIENT_CARD:
        return !isFromPatientDetails && !!patientUrl;
      case CHAT_HEADER_ICONS.SHOW_CLOSE:
        return isUserPartOfTicket;
      case CHAT_HEADER_ICONS.REASSIGN_OWNER:
        return (
          showCloseButton && !isClosed && !isExternalUser && isUserPartOfTicket
        );
      case CHAT_HEADER_ICONS.SHOW_USER_LIST:
        return !isExternalUser && isFromPatientDetails;
      case CHAT_HEADER_ICONS.IMAGE_GALLERY:
        return isUserPartOfTicket;
      default:
        return false;
    }
  };

  const handleSnoozePopupClose = () => {
    setIsSnoozePopupOpen(false);
  };

  const handleSnoozePopupOpen = () => {
    setIsSnoozePopupOpen(true);
  };

  const handleSnooze = () => {
    handleSnoozePopupOpen();
  };

  const [snoozeTicket] = useSnoozeTicket({
    onCompleted(data: SnoozeTicketOutput) {
      const { snoozeTicket: { ticketId, id, snoozeUntil } = {} } = data;
      setTicketsList((prevList) => {
        const tickets = cloneDeep(prevList);
        const snoozedTicket = tickets.find((ticket) => ticket.id == ticketId);
        if (snoozedTicket && id && snoozeUntil)
          snoozedTicket.snoozeInfo = {
            id,
            snoozeUntil
          };
        return tickets;
      });
      getSelectedTicketDetails?.();
      showToast(translate('snooze.success'), true);
    },
    refetchQueries: [GET_TICKET_COUNT]
  });

  const [unsnoozeTicket, { loading: unsnoozeLoading }] = useUnsnoozeTicket({
    onCompleted(data: UnsnoozeTicketOutput) {
      const { unsnoozeTicket: { ticketId } = {} } = data;
      setTicketsList((prevList) => {
        const tickets = cloneDeep(prevList);
        const unsnoozedTicket = tickets.find((ticket) => ticket.id == ticketId);
        if (unsnoozedTicket) {
          delete unsnoozedTicket.snoozeInfo;
        }
        return tickets;
      });
      getSelectedTicketDetails?.();
      showToast(translate('unsnooze.success'), true);
    },
    refetchQueries: [GET_TICKET_COUNT]
  });

  const handleSnoozeConfirm = (time: moment.Moment, snoozeReason: string) => {
    const params = {
      variables: {
        ticketId: chatId,
        input: {
          snoozeType: snoozeReason,
          snoozeUntil: time.toDate()
        }
      }
    };
    snoozeTicket(params);
    handleSnoozePopupClose();
  };

  const handleUnsnooze = () => {
    const params = {
      variables: {
        ticketId: chatId
      }
    };
    unsnoozeTicket(params);
  };

  const [showChatGallery, toggleShowChatGallery] = useToggle(false);

  const openChatGallery = () => {
    getPhotos();
    toggleShowChatGallery(true);
  };

  const {
    photos,
    totalCount,
    onClose: galleryClose,
    isLoading: isPhotosLoading,
    next: getPhotos
  } = useChatGallery(chatId);

  const closeChatGallery = () => {
    galleryClose();
    toggleShowChatGallery(false);
  };

  const modalContainerEl = document.getElementById('modal-container');

  return (
    <>
      <div
        className={customTwMerge(
          cx(
            'flex justify-between px-5 py-4 md:py-2 md:border-b-1 border-LINE-COLOR',
            {
              'flex-col md:px-0 md:border-0': isFromPatientDetails,
              'px-6': !isFromPatientDetails
            }
          )
        )}
      >
        <div className='text-DEFAULT_TEXT w-full md:flex-1'>
          <div className='flex justify-between'>
            <If condition={!isFromPatientDetails}>
              <div className='md:font-bold md:font-normal text-base'>
                {patientName.trim()}
              </div>
            </If>
            <If condition={isFromPatientDetails}>
              <h2 className='text-base md:text-3xl mb-2'>{title}</h2>
            </If>
          </div>
          <If condition={isFromPatientDetails && showTpvLink()}>
            <p className='hidden md:block text-DARK_GRAY text-sm'>
              <a>{getSubtitleForPatientDetails()}</a>
            </p>
          </If>
          <If condition={!isFromPatientDetails}>
            <p className='text-14px leading-6 xxs:text-DISABLED_2 md:text-DARK_GRAY'>
              {subtitle ? <div>{getSubtitle()}</div> : title}
            </p>
            <p className='md:hidden text-14px text-DISABLED_2'>{`${translate(
              'INBOX.CHAT_ID'
            )}: ${chatId}`}</p>
          </If>
          <If condition={isFromPatientDetails}>
            <div className='md:hidden'>
              <p className='text-DARK_GRAY text-sm'>
                {translate('patient_name.patient_name')}: {patientName}
              </p>
              <p className='text-DARK_GRAY text-14px leading-6 mb-2 md:hidden'>
                {translate('INBOX.CHAT_ID')} - #{chatId}
              </p>
            </div>
          </If>
          <div className='flex gap-x-4 mt-2 md:hidden'>
            <>
              {checkPermission(CHAT_HEADER_ICONS.MARK_AS_NOT_READ) && (
                <button
                  onClick={() => handleUnread?.(!isUnread)}
                  data-testid='mark-as-read'
                >
                  <MailOutlineIcon
                    className={cx('w-4 h-4', {
                      'text-PRIMARY': !isUnread
                    })}
                  />
                </button>
              )}

              <CopyText
                copyText={`${window.location.origin}/${patientUrl}?ticketId=${chatId}`}
                showCopyIcon={false}
                displayText={
                  <CopyLink className={cx('w-4 h-3.5 text-PRIMARY')} />
                }
                successToastMessage={translate('success.ticket_link_copied')}
                copyHandler={copyLink}
              />
              <If condition={checkPermission(CHAT_HEADER_ICONS.IMAGE_GALLERY)}>
                <button onClick={openChatGallery} data-testid='gallery-btn'>
                  <PhotoLibraryIcon />
                </button>
              </If>
              <a
                href={patientUrl}
                onClick={onPatientCardOpen}
                target='_blank'
                rel='noreferrer'
              >
                <NewTabIcon className='w-4 h-4' />
              </a>
            </>
          </div>
        </div>
        <If condition={isFromPatientDetails}>
          <div className='hidden md:block'>
            <p className='text-DARK_GRAY text-sm'>
              {translate('patient_name.patient_name')}: {patientName}
            </p>
            <p className='text-DARK_GRAY text-14px leading-6 mb-2 hidden md:block'>
              {translate('INBOX.CHAT_ID')} - #{chatId}
            </p>
          </div>
        </If>
        <div className='xxs:hidden md:flex items-end'>
          <div className='flex items-center gap-4'>
            <>
              {checkPermission(CHAT_HEADER_ICONS.SHOW_CLOSE) && (
                <button
                  className='flex gap-1 px-2.5 py-1 bg-BLACK rounded-md'
                  onClick={() => setIsOpen(true)}
                  data-testid='markAsClosed-btn'
                >
                  <div className=' text-xs text-WHITE'>
                    {isClosed
                      ? translate('chat.action.reopen')
                      : translate('chat.action.close')}
                  </div>
                  <RoundTickIcon />
                </button>
              )}
              <If condition={showSnooze}>
                <SnoozeButton
                  isSnoozed={isSnoozed}
                  onSnooze={handleSnooze}
                  onUnsnooze={handleUnsnooze}
                  snoozeUntill={snoozeUntill}
                  snoozeType={snoozeType}
                  disabled={unsnoozeLoading}
                />
              </If>
              {checkPermission(CHAT_HEADER_ICONS.MARK_AS_NOT_READ) && (
                <ChatHeaderTooltip
                  messageText={
                    isUnread
                      ? translate('INBOX.CHAT_HEADER.MARK_AS_NOT_READ')
                      : translate('notifications.mark_as_read')
                  }
                  containerClassName='flex'
                >
                  <button
                    className='flex'
                    data-testid='unread-btn'
                    onClick={() => handleUnread?.(!isUnread)}
                  >
                    <MailOutlineIcon
                      className={cx('w-4 h-4', {
                        'text-PRIMARY': !isUnread
                      })}
                    />
                  </button>
                </ChatHeaderTooltip>
              )}

              <ChatHeaderTooltip
                messageText={translate('notifications.mark_as_copied')}
              >
                <CopyText
                  copyText={`${window.location.origin}/${patientUrl}?ticketId=${chatId}`}
                  showCopyIcon={false}
                  displayText={
                    <CopyLink className={cx('w-4 h-4 text-PRIMARY')} />
                  }
                  successToastMessage={translate('success.ticket_link_copied')}
                  copyHandler={copyLink}
                />
              </ChatHeaderTooltip>
              <If condition={checkPermission(CHAT_HEADER_ICONS.IMAGE_GALLERY)}>
                <ChatHeaderTooltip
                  messageText={translate('inbox.gallery.title')}
                >
                  <button
                    className='flex'
                    onClick={openChatGallery}
                    data-testid='gallery-btn'
                  >
                    <PhotoLibraryIcon />
                  </button>
                </ChatHeaderTooltip>
              </If>

              {checkPermission(CHAT_HEADER_ICONS.MARK_AS_NOT_URGENT) && (
                <ChatHeaderTooltip
                  messageText={
                    isUrgent
                      ? translate('INBOX.CHAT_HEADER.MARK_AS_NOT_URGENT')
                      : translate('actions.markUrgent')
                  }
                  containerClassName='flex'
                >
                  <button
                    className='flex'
                    onClick={handleUrgent}
                    data-testid='flag-btn'
                  >
                    <FlagIcon
                      data-testid='mark-as-urgent'
                      className={cx('w-4 h-4', {
                        'text-PRIMARY': isUrgent
                      })}
                    />
                  </button>
                </ChatHeaderTooltip>
              )}
            </>
            {checkPermission(CHAT_HEADER_ICONS.OPEN_PATIENT_CARD) && (
              <ChatHeaderTooltip
                messageText={translate('actions.openPatientCard')}
              >
                <a
                  href={patientUrl}
                  onClick={onPatientCardOpen}
                  target='_blank'
                  rel='noreferrer'
                  data-testid='open-patient-btn'
                >
                  <NewTabIcon data-testid='new-tab-icon' className='w-4 h-4' />
                </a>
              </ChatHeaderTooltip>
            )}
            {checkPermission(CHAT_HEADER_ICONS.REASSIGN_OWNER) && (
              <>
                <ChatHeaderTooltip
                  messageText={translate('inbox.reassign_owner')}
                >
                  <button
                    className='flex'
                    onClick={() => setIsReassignOpen(true)}
                    data-testid='reassign-btn'
                  >
                    <ReassignIcon
                      className={cx('w-4 h-4', {
                        'text-PRIMARY': isClosed
                      })}
                    />
                  </button>
                </ChatHeaderTooltip>
              </>
            )}
            {checkPermission(CHAT_HEADER_ICONS.SHOW_USER_LIST) &&
              !isTPCouncil && (
                <>
                  <ChatHeaderTooltip
                    messageText={translate('INBOX.ADD_PARTICIPANT')}
                  >
                    <div>
                      <button
                        className='flex'
                        data-testid='user-list-btn'
                        onClick={() => setShowUserList(true)}
                      >
                        <ParticipantIcon className='w-4 h-4' />
                      </button>
                    </div>
                  </ChatHeaderTooltip>
                  <AddTeamMember
                    participantsList={userList}
                    isAddParticipantPopupOpen={showUserList}
                    isFromTicketDetails={true}
                    onClose={() => {
                      setShowUserList(false);
                    }}
                  />
                </>
              )}
            <If condition={!showSidePanel && !isFromPatientDetails}>
              <button
                className='flex items-center'
                data-testid='inbox-side-btn'
                onClick={() => setShowSidePanel(true)}
              >
                <ChatHeaderTooltip
                  tooltipStyle='-mr-5'
                  messageText={translate('INBOX.OPEN_RIGHT_PANEL')}
                >
                  <RightPanelIcon />
                </ChatHeaderTooltip>
              </button>
            </If>
          </div>
        </div>
        <div className='flex flex-col md:hidden absolute top-4 right-6 justify-end items-end'>
          <button
            onClick={onClose}
            className='mt-0.5 block'
            data-testid='close-btn'
          >
            <CloseIcon className='h-4 w-4' />
          </button>
        </div>
        {checkPermission(CHAT_HEADER_ICONS.SHOW_USER_LIST) && (
          <>
            <div className='md:hidden'>
              <button
                className='text-PRIMARY underline text-sm'
                data-testid='show-user-list-btn'
                onClick={() => setShowUserList(true)}
              >
                {translate('INBOX.PARTICIPANTS_LIST')}
              </button>
            </div>
            <AddTeamMember
              participantsList={userList}
              isAddParticipantPopupOpen={showUserList}
              isFromTicketDetails={true}
              onClose={() => {
                setShowUserList(false);
              }}
            />
          </>
        )}
        <CloseTicketPopup isOpen={isOpen} setIsOpen={setIsOpen} />
        <ReassignOwner
          isOpen={isReassignOpen}
          setIsOpen={setIsReassignOpen}
          ticketId={chatId}
          participants={userList}
        />
        {isSnoozePopupOpen && (
          <SnoozeTicketPopup
            isOpen={isSnoozePopupOpen}
            onClose={handleSnoozePopupClose}
            onConfirm={handleSnoozeConfirm}
          />
        )}
      </div>
      {modalContainerEl &&
        showChatGallery &&
        createPortal(
          <ChatGallery
            chatId={chatId}
            onClose={closeChatGallery}
            photos={photos}
            totalCount={totalCount}
            isLoading={isPhotosLoading}
            next={getPhotos}
          />,
          modalContainerEl
        )}
    </>
  );
};

export default ChatHeader;
