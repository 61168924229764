import { pagePaths } from 'config/pages';
import { InboxFilterOptions } from 'types/Inbox.types';

const TICKET_STATUS = Object.freeze({
  CLOSE: 'CLOSE',
  URGENT: 'URGENT',
  OPEN: 'OPEN'
});

const TICKET_DETAILS = Object.freeze({
  TP: 'TP',
  TPV: 'TPV',
  PATIENT: 'PATIENT',
  CHAT_OWNER: 'CHAT_OWNER'
});

const INBOX_ROLE = {
  INTERNAL: 'internal',
  EXTERNAL: 'external'
};

const INBOX_CONTEXT_TYPE = {
  PATIENT: 'PATIENT',
  TP: 'TP',
  TPV: 'TPV'
};

const PATIENT_INBOX_KEY = {
  MY_CHAT: 'MY_CHAT',
  OTHERS: 'OTHERS'
};

export enum PopupScreen {
  CONFIRM = 'CONFIRM',
  EDIT = 'EDIT'
}

const INBOX_GROUP_NAME = {
  CHAT_INITIATOR: 'CHAT_INITIATOR',
  CHAT_OWNER: 'CHAT_OWNER',
  CHAT_SUPER_USER: 'CHAT_SUPER_USER',
  CHAT_MEMBER: 'CHAT_MEMBER',
  SUPER_ADMIN: 'SUPER_ADMIN'
};
const INBOX_NOTIFICATION = {
  GROUP_PROPERTY_UPADTED: 'GROUP_PROPERTY_UPADTED',
  IS_CLOSED: 'IS_CLOSED',
  CLOSE: TICKET_STATUS.CLOSE,
  OWNERSHIP_UPDATED: 'OWNERSHIP_UPDATED',
  OPEN: TICKET_STATUS.OPEN,
  IS_URGENT: 'IS_URGENT',
  GROUP_USERS_REMOVED: 'GROUP_USERS_REMOVED',
  GROUP_CREATED: 'GROUP_CREATED',
  GROUP_USERS_ADDED: 'GROUP_USERS_ADDED',
  EDIT_MESSAGE: 'EDIT_MESSAGE',
  MENTIONED_MESSAGE_ID: 'MENTIONED_MESSAGE_ID',
  IS_SNOOZED: 'IS_SNOOZED'
};
export const INBOX_GROUP_NOTIFICATION_UPDATES = [
  INBOX_NOTIFICATION.GROUP_USERS_REMOVED,
  // INBOX_NOTIFICATION.GROUP_USERS_ADDED,
  INBOX_NOTIFICATION.GROUP_CREATED
];

const TEAM_NAME = {
  CUSTOMER_CARE: {
    key: 'CUSTOMER_CARE',
    value: 'Customer Care'
  },
  NURSE: {
    key: 'NURSE',
    value: 'Nurse'
  },
  CASE_OPERATIONS: { key: 'CASE_OPERATIONS', value: 'Case Operations' },
  CUSTOMER_SUCCESS: { key: 'CUSTOMER_SUCCESS', value: 'Customer Success' },
  PARTNERSHIP_MANAGER: { key: 'PARTNERSHIP_MANAGER', value: 'Partnership' },
  EXTERNAL_DOCTOR: { key: 'EXTERNAL_DOCTOR', value: 'External Doctor' },
  TREATMENT_PLANNING_LEAD: {
    key: 'TREATMENT_PLANNING_LEAD',
    value: 'Treatment planning lead'
  },
  PRE_ASSESSMENT_LEAD: {
    key: 'PRE_ASSESSMENT_LEAD',
    value: 'Pre assessment lead'
  },
  TREATMENT_PLANNING_DOCTOR: {
    key: 'TREATMENT_PLANNING_DOCTOR',
    value: 'Treatment Planning Doctor'
  },
  PRE_ASSESSMENT_DOCTOR: {
    key: 'PRE_ASSESSMENT_DOCTOR',
    value: 'Pre Assessment Doctor'
  },
  CLINICAL_TRAINER_HQ: {
    key: 'CLINICAL_TRAINER_HQ',
    value: 'Clinical Trainer HQ'
  },
  CLINICAL_TRAINER: {
    key: 'CLINICAL_TRAINER',
    value: 'Clinical Trainer'
  },
  CS_ADMIN: {
    key: 'CS_ADMIN',
    value: 'CS Admin'
  },
  CC_ADMIN: {
    key: 'CC_ADMIN',
    value: 'CC Admin'
  },
  SUPER_ADMIN: {
    key: 'SUPER_ADMIN',
    value: 'Super Admin'
  }
};

const SUPPORTED_FILE_FORMATS = {
  '.jpg': ['image/jpg', 'image/jpeg'],
  '.jpeg': ['image/jpeg', 'image/jpg'],
  '.png': ['image/png'],
  '.pdf': ['application/pdf'],
  '.mp4': ['video/mp4'],
  '.mov': ['video/quicktime'],
  '.wmv': ['video/x-ms-wmv'],
  '.doc': ['application/msword'],
  '.docx': [
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  ],
  '.ppt': ['application/vnd.ms-powerpoint'],
  '.pptx': [
    'application/vnd.openxmlformats-officedocument.presentationml.presentation'
  ],
  '.csv': ['text/csv'],
  '.xls': ['application/vnd.ms-excel'],
  '.xlsx': [
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  ],
  '.xlsb': ['application/vnd.ms-excel.sheet.binary.macroEnabled.12'],
  '.txt': ['text/plain'],
  '.stl': [
    'application/sla',
    'application/vnd.ms-pki.stl',
    'model/x.stl-binary',
    'model/stl',
    'model/x.stl-ascii',
    '' // in safari MIME type is ''
  ],
  '.obj': [
    'model/obj',
    'application/x-tgif',
    '' // in safari MIME type is ''
  ]
};
const CHAT_TOPIC_INITIATOR = {
  INTERNAL_ZENYUM_STAKEHOLDER: 'INTERNAL_ZENYUM_STAKEHOLDER',
  EXTERNAL_DOCTOR: 'EXTERNAL_DOCTOR'
};

const RECENT_MESSAGE_MAX_LENGTH = 50;
const TREATMENT_STAGE_CODE = {
  GENERAL: 'GENERAL',
  END_OF_TREATMENT: 'END_OF_TREATMENT_REVIEW'
};
const MESSAGE_TYPE = {
  GROUP_STATUS_UPDATE: 'GROUP_STATUS_UPDATE',
  MENTION: 'MENTION',
  GROUP_SNOOZE_STATUS_UPDATE: 'GROUP_SNOOZE_STATUS_UPDATE'
};

const CHAT_HEADER_ICONS = {
  MARK_AS_NOT_READ: 'MARK_AS_NOT_READ',
  MARK_AS_NOT_URGENT: 'MARK_AS_NOT_URGENT',
  MARK_AS_COPIED: 'MARK_AS_COPIED',
  OPEN_PATIENT_CARD: 'OPEN_PATIENT_CARD',
  SHOW_CLOSE: 'SHOW_CLOSE',
  REASSIGN_OWNER: 'REASSIGN_OWNER',
  SHOW_USER_LIST: 'SHOW_USER_LIST',
  IMAGE_GALLERY: 'IMAGE_GALLERY'
};

const CHAT_FILTER_VALUES = Object.freeze({
  SELF: 'SELF',
  OTHERS: 'OTHERS',
  URGENT: 'URGENT',
  NOT_URGENT: 'NOT_URGENT',
  READ: 'READ',
  UNREAD: 'UNREAD',
  OPEN: 'OPEN',
  CLOSE: 'CLOSE',
  MENTIONED_ME: 'MENTIONED_ME',
  DO_NOT_MENTIONED_ME: 'DO_NOT_MENTIONED_ME',
  PENDING_ZENYUM: 'PENDING_ZENYUM',
  PENDING_TREATING_DOCTOR: 'PENDING_TREATING_DOCTOR',
  PENDING_PATIENT: 'PENDING_PATIENT',
  NOT_SNOOZE: 'NONE',
  REFERRALS_YES: 'Yes',
  REFERRALS_NO: 'No'
});

const CHAT_FILTER_LABEL = {
  CREATED_BY_ME: 'filters.createdByMe',
  NOT_CREATE_BY_ME: 'filters.notCreatedByMe',
  OTHER: 'Inbox.filter.other',
  URGENT: 'labels.urgent',
  NOT_URGENT: 'Inbox.filter.notUrgent',
  READ: 'Inbox.filter.read',
  UNREAD: 'labels.unread',
  ON_GOING: 'labels.ongoing',
  CLOSED: 'messageStatus.closed',
  OWNED_BY_ME: 'filters.ownedByMe',
  NOT_OWNED_BY_ME: 'filters.notOwnedByMe',
  MENTIONED_ME: 'Inbox.filter.mentionMe',
  DO_NOT_MENTIONED_ME: 'Inbox.filter.doNotMentionMe',
  PENDING_ZENYUM: 'filters.pending_zenyum',
  PENDING_TREATING_DOCTOR: 'filters.pending_doctor',
  PENDING_PATIENT: 'filters.pending_patient',
  NOT_SNOOZE: 'filters.not_snooze',
  REFERRALS_YES: 'yes.yes',
  REFERRALS_NO: 'no.no'
};

const INBOX_FILTERS = {
  CREATED_BY: [
    {
      value: CHAT_FILTER_VALUES.SELF,
      key: CHAT_FILTER_VALUES.SELF,
      label: CHAT_FILTER_LABEL.CREATED_BY_ME
    },
    {
      value: CHAT_FILTER_VALUES.OTHERS,
      key: CHAT_FILTER_VALUES.OTHERS,
      label: CHAT_FILTER_LABEL.NOT_CREATE_BY_ME
    }
  ],
  PRIORITY: [
    {
      value: CHAT_FILTER_VALUES.URGENT,
      key: CHAT_FILTER_VALUES.URGENT,
      label: CHAT_FILTER_LABEL.URGENT
    },
    {
      value: CHAT_FILTER_VALUES.NOT_URGENT,
      key: CHAT_FILTER_VALUES.NOT_URGENT,
      label: CHAT_FILTER_LABEL.NOT_URGENT
    }
  ],
  READ: [
    {
      value: CHAT_FILTER_VALUES.READ,
      key: CHAT_FILTER_VALUES.READ,
      label: CHAT_FILTER_LABEL.READ
    },
    {
      value: CHAT_FILTER_VALUES.UNREAD,
      key: CHAT_FILTER_VALUES.UNREAD,
      label: CHAT_FILTER_LABEL.UNREAD
    }
  ],
  ONGOING: [
    {
      value: CHAT_FILTER_VALUES.OPEN,
      key: CHAT_FILTER_VALUES.OPEN,
      label: CHAT_FILTER_LABEL.ON_GOING
    },
    {
      value: CHAT_FILTER_VALUES.CLOSE,
      key: CHAT_FILTER_VALUES.CLOSE,
      label: CHAT_FILTER_LABEL.CLOSED
    }
  ],
  MENTIONS: [
    {
      value: true,
      key: CHAT_FILTER_VALUES.MENTIONED_ME,
      label: CHAT_FILTER_LABEL.MENTIONED_ME
    },
    {
      value: false,
      key: CHAT_FILTER_VALUES.DO_NOT_MENTIONED_ME,
      label: CHAT_FILTER_LABEL.DO_NOT_MENTIONED_ME
    }
  ],
  REFERRALS: [
    {
      value: true,
      key: CHAT_FILTER_VALUES.REFERRALS_YES,
      label: CHAT_FILTER_LABEL.REFERRALS_YES
    },
    {
      value: false,
      key: CHAT_FILTER_VALUES.REFERRALS_NO,
      label: CHAT_FILTER_LABEL.REFERRALS_NO
    }
  ],
  SNOOZE: [
    {
      value: CHAT_FILTER_VALUES.PENDING_ZENYUM,
      key: CHAT_FILTER_VALUES.PENDING_ZENYUM,
      label: CHAT_FILTER_LABEL.PENDING_ZENYUM
    },
    {
      value: CHAT_FILTER_VALUES.PENDING_TREATING_DOCTOR,
      key: CHAT_FILTER_VALUES.PENDING_TREATING_DOCTOR,
      label: CHAT_FILTER_LABEL.PENDING_TREATING_DOCTOR
    },
    {
      value: CHAT_FILTER_VALUES.PENDING_PATIENT,
      key: CHAT_FILTER_VALUES.PENDING_PATIENT,
      label: CHAT_FILTER_LABEL.PENDING_PATIENT
    },
    {
      value: CHAT_FILTER_VALUES.NOT_SNOOZE,
      key: CHAT_FILTER_VALUES.NOT_SNOOZE,
      label: CHAT_FILTER_LABEL.NOT_SNOOZE
    }
  ]
};

export enum INBOX_DROPDOWN_KEY {
  Doctor = 'doctorId',
  Topic = 'topicId',
  Clinic = 'clinicId'
}

export enum MEDIA_TYPE {
  IMAGE = 'IMAGE'
}

export enum ENTITY_TYPE {
  TICKET = 'TICKET'
}
export const SEGMENT_FILTER_KEY: Record<string, string> = {
  createdByUsers: 'Created by me',
  ownedBy: 'Owned by',
  urgency: 'Urgent',
  country: 'Country',
  clinicId: 'Clinic',
  doctorId: 'Doctor',
  topicId: 'Topic',
  readStatus: 'Unread',
  hasMentionedMe: 'Has mentioned me',
  isReferral: 'Referral'
};

const INBOX_DROPDOWN_OPTIONS: InboxFilterOptions[] = [
  {
    placeholder: ['Inbox.filter.createdBy'],
    key: 'createdByUsers',
    options: INBOX_FILTERS.CREATED_BY,
    showOnlyInternal: false,
    isMobileViewAvailable: true,
    enableSearch: false
  },
  {
    key: 'ownedBy',
    placeholder: ['Inbox.filter.ownedBy'],
    options: [],
    showOnlyInternal: true,
    isMobileViewAvailable: false,
    enableSearch: true
  },
  {
    placeholder: ['Inbox.filter.priority'],
    key: 'urgency',
    options: INBOX_FILTERS.PRIORITY,
    showOnlyInternal: false,
    isMobileViewAvailable: true,
    enableSearch: false
  },
  {
    key: 'country',
    placeholder: ['country.country'],
    options: [],
    showOnlyInternal: false,
    isMobileViewAvailable: true,
    enableSearch: true
  },
  {
    placeholder: ['clinic.clinic'],
    key: INBOX_DROPDOWN_KEY.Clinic,
    options: [],
    showOnlyInternal: true,
    isMobileViewAvailable: false,
    enableSearch: true
  },
  {
    key: INBOX_DROPDOWN_KEY.Topic,
    placeholder: ['inbox.filter.topic'],
    options: [],
    showOnlyInternal: false,
    isMobileViewAvailable: false,
    enableSearch: true
  },
  {
    placeholder: ['doctor.doctor'],
    key: INBOX_DROPDOWN_KEY.Doctor,
    options: [],
    showOnlyInternal: true,
    isMobileViewAvailable: false,
    enableSearch: true
  },
  {
    key: 'readStatus',
    placeholder: ['Inbox.filter.read', 'labels.unread'],
    options: INBOX_FILTERS.READ,
    showOnlyInternal: false,
    isMobileViewAvailable: true,
    enableSearch: false
  },
  {
    key: 'hasMentionedMe',
    placeholder: ['Inbox.filter.mention'],
    options: INBOX_FILTERS.MENTIONS,
    showOnlyInternal: true,
    isMobileViewAvailable: true,
    enableSearch: false
  },
  {
    key: 'isReferral',
    placeholder: ['inbox.filter.clinic_referrals'],
    options: INBOX_FILTERS.REFERRALS,
    showOnlyInternal: false,
    isMobileViewAvailable: true,
    enableSearch: false
  }
  // {
  //   key: 'snoozeType',
  //   placeholder: ['snooze.snooze_reasons'],
  //   options: INBOX_FILTERS.SNOOZE,
  //   showOnlyInternal: true
  // }
];

export enum TICKET_SEARCH_TARGET {
  TICKET = 'TICKET',
  MESSAGE = 'MESSAGE'
}

const SEGMENT_STATUS = {
  OPENED: 'opened',
  CLOSED: 'closed'
};

const TABLIST = [
  {
    id: INBOX_ROLE.INTERNAL,
    label: 'messageBox.internal'
  },
  {
    id: INBOX_ROLE.EXTERNAL,
    label: 'MessageBox.externalDoc'
  }
];

const INBOX_BREADCRUMBS = [
  {
    label: 'dashboard_button.dashboard',
    pathname: pagePaths.dashboard
  },
  {
    label: 'inbox_button.inbox'
  }
];

type Label = {
  label: string;
  classNames: string;
  snoozeType: string;
  toolTipMsg: string;
};

const INTERNAL_LABELS: Record<string, Label> = {
  TODO: {
    label: 'filters.to_do',
    classNames: 'bg-LIGHT_BLUE_HEADER border-blue-400',
    snoozeType: 'TODO',
    toolTipMsg: 'action_btn.to_do'
  },
  PENDING_ZENYUM: {
    label: 'filters.pending_zenyum',
    classNames: 'bg-LIGHT_YELLOW border-yellow-500',
    snoozeType: 'PENDING_ZENYUM',
    toolTipMsg: 'action_btn.pending_zenyum'
  },
  PENDING_TREATING_DOCTOR: {
    label: 'filters.pending_doctor',
    classNames: 'bg-LIGHT_ORANGE border-orange-400',
    snoozeType: 'PENDING_TREATING_DOCTOR',
    toolTipMsg: 'action_btn.pending_doctor'
  },
  PENDING_PATIENT: {
    label: 'filters.pending_patient',
    classNames: 'bg-LIGHT_ROSE border-rose-400',
    snoozeType: 'PENDING_PATIENT',
    toolTipMsg: 'action_btn.pending_patient'
  },
  ALL: {
    label: 'filters.all_open',
    classNames: 'bg-LIGHT_GREEN border-green-600',
    snoozeType: 'ALL',
    toolTipMsg: 'action_btn.all'
  },
  CLOSED: {
    label: 'inbox.filter.closed',
    classNames: '',
    snoozeType: 'CLOSED',
    toolTipMsg: 'All closed tickets'
  }
};

const EXTERNAL_LABELS: Record<string, Label> = {
  ALL: {
    label: 'inbox.filter.open',
    snoozeType: 'ALL',
    classNames: 'bg-LIGHT_BLUE_HEADER border-blue-400',
    toolTipMsg: 'Open tickets'
  },
  CLOSED: {
    label: 'inbox.filter.closed',
    snoozeType: 'CLOSED',
    classNames: 'bg-LIGHT_BLUE_HEADER border-blue-400',
    toolTipMsg: 'All closed tickets'
  }
};

type SnoozeFilters = {
  status: string[];
  snoozeType: string[];
};

const SNOOZE_INBOX_FILTER: Record<string, SnoozeFilters> = {
  TODO: {
    status: ['OPEN'],
    snoozeType: ['NONE']
  },
  PENDING_ZENYUM: {
    status: ['OPEN'],
    snoozeType: ['PENDING_ZENYUM']
  },
  PENDING_TREATING_DOCTOR: {
    status: ['OPEN'],
    snoozeType: ['PENDING_TREATING_DOCTOR']
  },
  PENDING_PATIENT: {
    status: ['OPEN'],
    snoozeType: ['PENDING_PATIENT']
  },
  ALL: {
    status: ['OPEN'],
    snoozeType: []
  },
  CLOSED: {
    status: ['CLOSE'],
    snoozeType: []
  }
};

const INBOX_FILTERS_HIDDEN_FOR_TP_COUNCIL = [
  INBOX_DROPDOWN_KEY.Clinic,
  INBOX_DROPDOWN_KEY.Doctor
];

const BULK_REASSIGN_LIMIT = 50;

export {
  TEAM_NAME,
  INBOX_ROLE,
  MESSAGE_TYPE,
  TICKET_STATUS,
  SEGMENT_STATUS,
  TICKET_DETAILS,
  INBOX_GROUP_NAME,
  CHAT_HEADER_ICONS,
  PATIENT_INBOX_KEY,
  INBOX_NOTIFICATION,
  INBOX_CONTEXT_TYPE,
  CHAT_TOPIC_INITIATOR,
  SNOOZE_INBOX_FILTER,
  TREATMENT_STAGE_CODE,
  SUPPORTED_FILE_FORMATS,
  INBOX_DROPDOWN_OPTIONS,
  RECENT_MESSAGE_MAX_LENGTH,
  INBOX_FILTERS_HIDDEN_FOR_TP_COUNCIL,
  TABLIST,
  INBOX_BREADCRUMBS,
  INTERNAL_LABELS,
  EXTERNAL_LABELS,
  BULK_REASSIGN_LIMIT
};
