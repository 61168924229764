import { gql } from '@apollo/client';

const GET_ALL_AFTER_SALES_APPOINTMENTS = gql`
  query ($filter: AftersalesAppointmentsFilter) @api(name: treatmentMonitor) {
    getAllAfterSalesAppointments(filter: $filter) {
      totalCount: total_count
      appointments {
        country
        patientName: patient_name
        doctorSfId: doctor_sf_id
        clinicSfId: clinic_sf_id
        doctorClinic: doctor_clinic
        doctorName: doctor_name
        treatmentId: treatment_id
        treatmentType: treatment_type
        opportunityId: opportunity_id
        appointmentType: appointment_type_label
        expectedAppointmentDate: expected_appointment_date
        actualAppointmentDate: actual_appointment_date
        appointmentStatus: appointment_status
        appointmentId: appointment_id
        appointmentAction: appointment_action
        appointmentStatusLabel: status_label
        startTime: start_time
        endTime: end_time
        doctorClinic: doctor_clinic
        doctorName: doctor_name
        createdReason: created_reason
        advanceNoticePeriod: advance_notice_period
        setNumber: before_set_number
        appointmentOrder: appointment_order
        enabled
        comments {
          noteId: note_id
          description
        }
        opportunityType: opportunity_type
      }
    }
  }
`;
const GET_TREATMENT_BY_CP_ID = gql`
  query (
    $cpId: ID!
    $doctorSfId: String
    $clinicSfId: String
    $opportunityId: String
  ) {
    getTreatmentByCpId(cpId: $cpId, opportunityId: $opportunityId) {
      id
      patientId
      patient {
        email
        phone
        primaryCountry
      }
      doctorTimings(
        doctorSalesforceId: $doctorSfId
        clinicSalesforceId: $clinicSfId
      ) {
        availableHours {
          startTime
          endTime
        }
        day
      }
      doctorAppointmentPreference(doctorSalesforceId: $doctorSfId) {
        name
        code
        duration
      }
    }
  }
`;
const GET_ALL_APPOINTMENT_ACTIONS_COUNT = gql`
  query ($filter: AppointmentActionsCountFilter) @api(name: treatmentMonitor) {
    getAllAppointmentActionsCount(filter: $filter) {
      appointmentAction: appointment_action
      count: count
    }
  }
`;

const GET_ALL_APPOINTMENT_TYPES_COUNT = gql`
  query ($filter: AppointmentActionsCountFilter) @api(name: treatmentMonitor) {
    getAppointmentTypeCount(filter: $filter) {
      appointmentType: appointment_type
      count: count
    }
  }
`;

const CORE_APPOINTMENT_FIELDS = gql`
  fragment CoreAppointmentDetails on Appointment {
    patientName: patient_name
    appointmentType: appointment_type_label
    appointmentStatus: appointment_status
    appointmentStatusLabel: status_label
    doctorClinic: doctor_clinic
    doctorName: doctor_name
    actualAppointmentDate: actual_appointment_date
    startTime: start_time
    appointmentAction: appointment_action
    endTime: end_time
    enabled
    country
    treatmentType: treatment_type
    defaultDuration: default_duration
    appointmentId: appointment_id
    treatmentId: treatment_id
    createdReason: created_reason
    appointmentOrder: appointment_order
    alignerSetNo: before_set_number
    doctorSfId: doctor_sf_id
    clinicSfId: clinic_sf_id
    advanceNoticePeriod: advance_notice_period
    expectedAppointmentDate: expected_appointment_date
    comments {
      noteId: note_id
      description
    }
    patientPreferredSlots: patient_preferred_slots
    patient_preferred_slots_date_time {
      to
      from
    }
    opportunityId: opportunity_id
    appointmentAction: appointment_action
    rescheduledReason: reschedule_reason
    appointment_outcome: appointment_outcome
  }
`;

const GET_APPOINTMENT_DETAILS_BY_CP_ID = gql`
  ${CORE_APPOINTMENT_FIELDS}
  query ($id: ID!) @api(name: treatmentMonitor) {
    getAppointmentDetailsByCpId(id: $id) {
      ...CoreAppointmentDetails
    }
  }
`;

const EDIT_APPOINTMENT = gql`
  ${CORE_APPOINTMENT_FIELDS}
  mutation ($input: EditAppointmentInput!, $id: ID!)
  @api(name: treatmentMonitor) {
    editAppointment(input: $input, id: $id) {
      ...CoreAppointmentDetails
    }
  }
`;
const DELETE_APPOINTMENT = gql`
  mutation ($id: ID!, $type: AppointmentType, $is_retainer_appointment: Boolean)
  @api(name: treatmentMonitor) {
    deleteAppointment(
      id: $id
      type: $type
      is_retainer_appointment: $is_retainer_appointment
    ) {
      id
      message
    }
  }
`;

const EXPORT_APPOINTMENT = gql`
  mutation ($user_email: String!, $filter: AftersalesAppointmentsFilter)
  @api(name: treatmentMonitor) {
    exportAppointments(user_email: $user_email, filter: $filter) {
      message
    }
  }
`;

const CREATE_AFTER_SALES_APPOINTMENT = gql`
  ${CORE_APPOINTMENT_FIELDS}
  mutation ($opportunityId: ID!, $input: CreateAppointmentInput!)
  @api(name: treatmentMonitor) {
    createAppointment(input: $input, opportunityId: $opportunityId) {
      ...CoreAppointmentDetails
    }
  }
`;

const GET_EXPECTED_APPOINTMENT_DATE = gql`
  query ($cpTreatmentId: ID!, $input: GetExpectedAppointmentDateInput!)
  @api(name: treatmentMonitor) {
    getExpectedAppointmentDate(cpTreatmentId: $cpTreatmentId, input: $input) {
      expectedDate: expected_date
    }
  }
`;

const GET_TREATMENT_BY_SF_ID = gql`
  query ($sfId: ID!) @api(name: treatmentMonitor) {
    getTreatmentBySfId(sfId: $sfId) {
      treatment {
        id
        no_of_aligner
      }
    }
  }
`;

const GET_ALL_APPOINTMENTS_FOR_OPPORTUNITY = gql`
  query ($filter: AftersalesAppointmentsFilter) @api(name: treatmentMonitor) {
    getAllAppointmentForOpportunities(filter: $filter) {
      country
      patientName: patient_name
      doctorSfId: doctor_sf_id
      clinicSfId: clinic_sf_id
      doctorClinic: doctor_clinic
      doctorName: doctor_name
      treatmentId: treatment_id
      opportunityId: opportunity_id
      appointmentType: appointment_type_label
      expectedAppointmentDate: expected_appointment_date
      actualAppointmentDate: actual_appointment_date
      appointmentStatus: appointment_status
      appointmentId: appointment_id
      appointmentAction: appointment_action
      appointmentStatusLabel: status_label
      startTime: start_time
      endTime: end_time
      doctorClinic: doctor_clinic
      doctorName: doctor_name
      createdReason: created_reason
      advanceNoticePeriod: advance_notice_period
      setNumber: before_set_number
      appointmentOrder: appointment_order
      rescheduledReason: reschedule_reason
      appointment_outcome: appointment_outcome
      enabled
      opportunityType: opportunity_type
      comments {
        noteId: note_id
        description
      }
    }
  }
`;
export {
  EDIT_APPOINTMENT,
  DELETE_APPOINTMENT,
  EXPORT_APPOINTMENT,
  GET_TREATMENT_BY_CP_ID,
  GET_EXPECTED_APPOINTMENT_DATE,
  CREATE_AFTER_SALES_APPOINTMENT,
  GET_ALL_AFTER_SALES_APPOINTMENTS,
  GET_APPOINTMENT_DETAILS_BY_CP_ID,
  GET_ALL_APPOINTMENT_ACTIONS_COUNT,
  GET_TREATMENT_BY_SF_ID,
  GET_ALL_APPOINTMENTS_FOR_OPPORTUNITY,
  GET_ALL_APPOINTMENT_TYPES_COUNT
};
