import { FC, useState } from 'react';

import { CloseIcon } from 'assets/icons';
import Button from 'components/Button/Button';
import { OUTCOME_BACKEND } from 'constants/calendarConstants';
import { EVENT_DETAILS_ACTIONS } from 'constants/options';
import { translate } from 'utils';

interface ApptOutcomePopupProps {
  handleCancelClick(): void;
  onClose(): void;
  handleAction?: (type: EVENT_DETAILS_ACTIONS) => void;
  handleConfirm: (outcome: string | null) => void;
}

export const ApptOutcomePopup: FC<ApptOutcomePopupProps> = ({
  onClose,
  handleAction,
  handleCancelClick,
  handleConfirm
}) => {
  const [selectedOption, setSelectedOption] = useState(null);

  const handleOptionChange = (event: any) => {
    setSelectedOption(event.target.value);
  };
  const handleSave = (selectedOption: string | null) => {
    handleConfirm(selectedOption);
    handleAction?.(EVENT_DETAILS_ACTIONS.SHOWED_UP);
  };
  return (
    <div>
      <div className='flex mb-10 justify-between'>
        <div className='font-semibold text-xl'>
          {translate('outcome.select')}
        </div>
        <button className='w-3 h-3' onClick={onClose}>
          <CloseIcon />
        </button>
      </div>
      <div className='text-17px mb-5'>
        {translate('illfitting.description')}
      </div>
      <div className='mb-5'>
        <label className='flex items-start text-17px'>
          <input
            type='radio'
            value={OUTCOME_BACKEND.SUCCESS}
            checked={selectedOption === OUTCOME_BACKEND.SUCCESS}
            onChange={handleOptionChange}
            className='mr-2 mt-1 border-LINE_GRAY'
          />
          <p>
            {translate('outcome.success')}
            <span className='text-DISABLED_2 ml-1'>
              ({translate('outcome.success.description')})
            </span>
          </p>
        </label>
      </div>
      <div className='mb-5'>
        <label className='flex items-start text-17px'>
          <input
            type='radio'
            value={OUTCOME_BACKEND.ILL_FITTING_AT_FITTING}
            checked={selectedOption === OUTCOME_BACKEND.ILL_FITTING_AT_FITTING}
            onChange={handleOptionChange}
            className='mr-2 mt-1 border-LINE_GRAY'
          />
          <p>
            {translate('outcome.ill_fitting')}
            <span className='text-DISABLED_2 ml-1'>
              ({translate('outcome.ill_fitting.description')})
            </span>
          </p>
        </label>
      </div>
      <div className='mb-30px'>
        <label className='flex items-start text-17px'>
          <input
            type='radio'
            value={OUTCOME_BACKEND.OTHER_ISSUES}
            checked={selectedOption === OUTCOME_BACKEND.OTHER_ISSUES}
            onChange={handleOptionChange}
            className='mr-2 mt-1 border-LINE_GRAY'
          />
          <p>
            {translate('outcome.other')}
            <span className='text-DISABLED_2 ml-1'>
              ({translate('outcome.other.description')})
            </span>
          </p>
        </label>
      </div>
      <div className='flex flex-row space-x-4 justify-end'>
        <button
          onClick={handleCancelClick}
          className='text-14px px-4'
          type='button'
        >
          {translate('cancel.cancel')}
        </button>
        <Button
          onClick={() => handleSave(selectedOption)}
          type='button'
          className='px-6 py-3  text-white bg-PRIMARY rounded-32px'
          disabled={selectedOption === null}
        >
          {translate('confirm.confirm')}
        </Button>
      </div>
    </div>
  );
};
