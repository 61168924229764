import { useState } from 'react';

import { useApolloClient } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

import { Modal } from 'components';
import { pagePaths } from 'config/pages';
import { ApptOutcomePopup } from 'pages/Calendar/components/ApptOutcomePopup/ApptOutcomePopup';
import { CancelConfirmationPopup } from 'pages/Calendar/components/CancelConfirmationPopup/CancelConfirmationPopup';
import { submitOutcomeForAppointment } from 'services/hooks/InTreatment.hooks';
import { GET_ALL_APPOINTMENTS_FOR_OPPORTUNITY } from 'services/query/afterSalesAppointment.queries';
import { GET_TREATMENTS_BY_VIEW } from 'services/query/PatientList.queries';
import { GET_PATIENT_DATA } from 'services/query/patientslist';
import { SubmitOutcomeForAppointmentInfo } from 'types/InTreatmetn.types';
import { translate } from 'utils';
import { showToast } from 'utils/toast/toast.util';

interface AppointmentOutcomePopUpProps {
  isOutcomePopupOpen: boolean;
  appointmentId: string;
  onClose: () => void;
}

const AppointmentOutcomePopUp = ({
  isOutcomePopupOpen,
  appointmentId,
  onClose
}: AppointmentOutcomePopUpProps) => {
  const client = useApolloClient();
  const navigate = useNavigate();

  const [isConfirmPopupOpen, setIsConfirmPopupOpen] = useState(true);
  const [isCancelPopupOpen, setIsCancelPopupOpen] = useState(false);

  const config = {
    onCompleted: (submitOutcome: SubmitOutcomeForAppointmentInfo) => {
      onCloseModal();
      if (
        submitOutcome.submitOutcomeForAppointment.appointment_outcome ===
          'ILL_FITTING_AT_FITTING' &&
        submitOutcome.submitOutcomeForAppointment.successor_treatment_id
      ) {
        navigate(
          `${pagePaths.patientList}/${submitOutcome.submitOutcomeForAppointment.successor_treatment_id}?selectedTabId=REFINEMENT`
        );
        showToast(
          translate(
            'toast.patient_has_been_successfully_registered_for_refinement.'
          ),
          true
        );
      }
    },
    onError: () => {
      showToast(translate('error.selection'), false);
    },
    refetchQueries: [GET_PATIENT_DATA, GET_ALL_APPOINTMENTS_FOR_OPPORTUNITY]
  };

  const [submit] = submitOutcomeForAppointment(config);

  const handleConfirm = (outcome: string | null) => {
    submit({
      variables: {
        id: appointmentId,
        outcome: outcome
      }
    });
  };

  const handleCancelConfirm = async () => {
    setIsCancelPopupOpen(false);
    await client.refetchQueries({
      include: [GET_PATIENT_DATA, GET_TREATMENTS_BY_VIEW]
    });
    onCloseModal();
  };

  const handleReturnClick = () => {
    setIsConfirmPopupOpen(true);
    setIsCancelPopupOpen(false);
  };

  const onCloseModal = () => {
    setIsCancelPopupOpen(false);
    setIsConfirmPopupOpen(false);
    onClose();
  };
  return (
    <Modal
      open={isOutcomePopupOpen}
      onClose={onCloseModal}
      showCloseIcon={false}
    >
      {isConfirmPopupOpen && (
        <div className='p-6 w-658px'>
          <ApptOutcomePopup
            onClose={onCloseModal}
            handleCancelClick={() => {
              setIsCancelPopupOpen(true);
              setIsConfirmPopupOpen(false);
            }}
            handleConfirm={handleConfirm}
          />
        </div>
      )}

      {isCancelPopupOpen && (
        <div className='p-6 w-658px items-center'>
          <CancelConfirmationPopup
            onClose={onCloseModal}
            handleReturnClick={handleReturnClick}
            handleCancelConfirm={handleCancelConfirm}
          />
        </div>
      )}
    </Modal>
  );
};

export default AppointmentOutcomePopUp;
