import { FC } from 'react';

import { CloseIcon } from 'assets/icons';
import Button from 'components/Button/Button';
import { translate } from 'utils';

interface CancelConfirmationPopupProps {
  onClose(): void;
  handleReturnClick(): void;
  handleCancelConfirm(): void;
}

export const CancelConfirmationPopup: FC<CancelConfirmationPopupProps> = ({
  onClose,
  handleReturnClick,
  handleCancelConfirm
}) => {
  return (
    <>
      <div className='flex justify-between mb-10'>
        <div className='text-xl font-semibold'>
          {translate('cancel_popup.are_you_sure')}
        </div>
        <button onClick={onClose}>
          <CloseIcon />
        </button>
      </div>
      <div className='text-17px mb-5'>{translate('cancel_popup.content')}</div>
      <div className='flex flex-row space-x-4 justify-end'>
        <button
          onClick={handleReturnClick}
          className='text-14px px-4'
          type='button'
        >
          {translate('button.return')}
        </button>
        <Button
          onClick={handleCancelConfirm}
          type='button'
          className='px-6 py-3  text-white bg-PRIMARY rounded-32px'
        >
          {translate('cancel.confirm')}
        </Button>
      </div>
    </>
  );
};
