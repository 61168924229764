import { gql } from '@apollo/client';

const GET_LOGINED_USER_DETAILS = gql`
  query getLoggedInUser($date: Date!) {
    getLoggedInUserDetails {
      id
      email
      phone
      mobile
      lineId
      groups
      language
      username
      firstName
      lastName
      salutation
      headClinicId
      permissions
      profilePicture
      headClinics {
        label: name
        headClinicId
        value: id
        country
        referralBonus
      }
      country: primaryCountry
      clinic {
        name
        headClinicId
        id
        isAppointmentEnabled
        country
        isSOAEnabled
      }
      details {
        maxComplexity
      }
      capacity {
        capacity
      }
      available(date: $date)
      activeLeave(date: $date) {
        startDate
        endDate
        timezone
      }
    }
  }
`;

const PASSWORD_LOGIN = gql`
  mutation passwordLogin($email: String!, $password: String!) {
    passwordLogin(input: { email: $email, password: $password }) {
      accessToken
      refreshToken
    }
  }
`;
const RESET_PASSWORD = gql`
  mutation ($token: String!, $password: String!) {
    resetPassword(input: { token: $token, password: $password })
  }
`;
const SELECT_USER_CLINIC = gql`
  mutation selectClinic($clinicId: String!) {
    selectClinic(clinicId: $clinicId)
  }
`;
const VERIFY_PASSWORD_LINK = gql`
  query verifyPasswordResetLink($token: String!) {
    verifyPasswordResetLink(token: $token)
  }
`;

const USER_HEAD_CLINICS = gql`
  query getUserHeadClinics {
    getUserHeadClinics {
      label: name
      value: id
    }
  }
`;

const GET_CHAT_TOKEN = gql`
  query {
    getChatAccessToken {
      key
    }
  }
`;

const CHANGE_PASSWORD = gql`
  mutation ChangePassword($input: UserPasswordInput!) {
    changePassword(input: $input) {
      id
    }
  }
`;
const LOG_OUT = gql`
  mutation logOut {
    logout
  }
`;

export {
  GET_LOGINED_USER_DETAILS,
  PASSWORD_LOGIN,
  RESET_PASSWORD,
  VERIFY_PASSWORD_LINK,
  GET_CHAT_TOKEN,
  CHANGE_PASSWORD,
  LOG_OUT,
  USER_HEAD_CLINICS,
  SELECT_USER_CLINIC
};
