import {
  GET_TREATMENT_LIST_FILTERS,
  GET_LONGEST_PENDING_TREATMENT,
  GET_TREATMENT_LIST_VIEWS,
  GET_COUNT_BY_VIEW,
  GET_TREATMENT_ACTIONS,
  getTreatMentByViewQuery,
  GET_ALL_TREATMENT_STATUSES,
  GET_DELIVERY_OBJECTS_COUNT,
  GET_DELIVERY_OBJECTS_LIST
} from 'services/query/PatientList.queries';
import {
  EDIT_PATIENT,
  GET_FAMILIES_OF_PATIENT,
  GET_PATIENT_DATA,
  GET_PATIENT_DOCUMENT,
  GET_PATIENT_DOCUMENTS,
  GET_PATIENT_INFO,
  GET_RESUBMISSION_FILES,
  GET_TREATMENT_PAYMENT_DETAILS,
  GET_UNREAD_MESSAGES_COUNT,
  POLL_FOR_REFINEMENT,
  RESUBMISSION_FILES,
  SAVE_RETAINER,
  SAVE_WHITENING,
  SUBMIT_PICKUP_OUTCOME,
  SUBMIT_RETAINER,
  SUBMIT_WHITENING,
  UPDATE_PATIENT_DETAILS,
  UPDATE_TREATMENT_STATUS
} from 'services/query/patientslist';
import { UseQueryType } from 'types/hooks';
import {
  AuthMutation,
  getAllTreatmentStatuses,
  GetCountByView,
  getDeliveryObjectsCount,
  getDeliveryObjectsList,
  GetPatientDetails,
  GetPatientDocument,
  GetPatientDocuments,
  GetReSubmissionFiles,
  GetTreatementsByView,
  getTreatmentActions,
  GetTreatmentFamilies,
  GetTreatmentFamily,
  getTreatmentListViews,
  GetTreatmentPaymentDetails,
  GetUnReadMessageCount,
  MutationType,
  PatientListFilterData,
  ResubmissionFilesInfo,
  SubmitPickUpOutcome,
  SubmitRetainer,
  SubmitWhitening,
  UpdatePatientType,
  UpdateRetainer,
  UpdateWhitening,
  UpdateStatusById,
  GetPatientInfoType
} from 'types/PatientList.types';

import {
  UseGraphQlMutation,
  UseGraphQlMutationWithTpes,
  UseGraphQlQuery
} from './hooks';

/**
 * Function to get sample data
 * @param {QueryHookOptions=} config query options
 * @returns {QueryResult}
 */
const defaultConfig = {};

export const useGetTreatementList: UseQueryType<GetTreatementsByView> = (
  config
) => {
  return UseGraphQlQuery(
    getTreatMentByViewQuery(config?.variables?.view),
    config
  );
};
export const useGetLongestPendingTreatement: UseQueryType<
  GetTreatementsByView
> = (config) => {
  return UseGraphQlQuery(GET_LONGEST_PENDING_TREATMENT, config);
};

export const useGetPatientListFilters: UseQueryType<PatientListFilterData> = (
  config
) => {
  return UseGraphQlQuery(GET_TREATMENT_LIST_FILTERS, config);
};
export const useGetCountByView: UseQueryType<GetCountByView> = (config) => {
  return UseGraphQlQuery(GET_COUNT_BY_VIEW, config);
};

export const useSaveRetainer: UpdateRetainer = (config) => {
  return UseGraphQlMutationWithTpes(SAVE_RETAINER, config);
};

export const useSubmitRetainer: SubmitRetainer = (config) => {
  return UseGraphQlMutationWithTpes(SUBMIT_RETAINER, config);
};

export const useSaveWhitening: UpdateWhitening = (config) => {
  return UseGraphQlMutationWithTpes(SAVE_WHITENING, config);
};

export const useSubmitWhitening: SubmitWhitening = (config) => {
  return UseGraphQlMutationWithTpes(SUBMIT_WHITENING, config);
};

export const useSubmitPickUpOutcome: SubmitPickUpOutcome = (config) => {
  return UseGraphQlMutationWithTpes(SUBMIT_PICKUP_OUTCOME, config);
};

export const useGetTreatmentById: GetPatientDetails = (config) => {
  return UseGraphQlQuery(GET_PATIENT_DATA, config);
};

export const useGetTreatmentFamiliesOfPatient: GetTreatmentFamilies = (
  config
) => {
  return UseGraphQlQuery(GET_FAMILIES_OF_PATIENT, config);
};

export const useGetTreatmentPaymentDetails: GetTreatmentPaymentDetails = (
  config
) => {
  return UseGraphQlQuery(GET_TREATMENT_PAYMENT_DETAILS, config);
};
export const useGetUnreadMessageCount: GetUnReadMessageCount = (config) => {
  return UseGraphQlQuery(GET_UNREAD_MESSAGES_COUNT, config);
};
export const useGetResubmissionFiles: GetReSubmissionFiles = (config) => {
  return UseGraphQlQuery(GET_RESUBMISSION_FILES, config);
};

export const useGetPatientDocuments: GetPatientDocuments = (config) => {
  return UseGraphQlQuery(GET_PATIENT_DOCUMENTS, config);
};
export const useGetPatientDocument: GetPatientDocument = (config) => {
  return UseGraphQlQuery(GET_PATIENT_DOCUMENT, config);
};

export const updateTreatment: AuthMutation = (config = defaultConfig) => {
  return UseGraphQlMutation(UPDATE_PATIENT_DETAILS, config);
};
export const submitResubmissionFiles: ResubmissionFilesInfo = (
  config = defaultConfig
) => {
  return UseGraphQlMutation(RESUBMISSION_FILES, config);
};

export const useUpdateTreatmentStatusById: MutationType<UpdateStatusById> = (
  config = defaultConfig
) => {
  return UseGraphQlMutation(UPDATE_TREATMENT_STATUS, config);
};

export const usePollForRefinement: GetTreatmentFamily = (config) => {
  return UseGraphQlQuery(POLL_FOR_REFINEMENT, config);
};

export const useGetTreatmentListViews: UseQueryType<getTreatmentListViews> = (
  config = defaultConfig
) => {
  return UseGraphQlQuery(GET_TREATMENT_LIST_VIEWS, config);
};
export const useGetAllTreatmentStatuses: UseQueryType<
  getAllTreatmentStatuses
> = (config = defaultConfig) => {
  return UseGraphQlQuery(GET_ALL_TREATMENT_STATUSES, config);
};
export const useGetDeliveryObjectsStatus: UseQueryType<
  getDeliveryObjectsCount
> = (config = defaultConfig) => {
  return UseGraphQlQuery(GET_DELIVERY_OBJECTS_COUNT, config);
};
export const useGetDeliveryObjectsList: UseQueryType<getDeliveryObjectsList> = (
  config = defaultConfig
) => {
  return UseGraphQlQuery(GET_DELIVERY_OBJECTS_LIST, config);
};
export const useGetTreatmentActions: UseQueryType<getTreatmentActions> = (
  config = defaultConfig
) => {
  return UseGraphQlQuery(GET_TREATMENT_ACTIONS, config);
};

export const useUpdatePatient: MutationType<UpdatePatientType> = (
  config = defaultConfig
) => {
  return UseGraphQlMutation(EDIT_PATIENT, config);
};

export const useGetPatientInfo: UseQueryType<GetPatientInfoType> = (
  config = defaultConfig
) => {
  return UseGraphQlQuery(GET_PATIENT_INFO, config);
};
